<template>
  <div class="date-input-container">
    <div class="alert">* 양력으로 입력해주세요</div>
    <div class="date-input-set">
      <input
          v-model="yearInput"
          type="tel"
          placeholder="0000"
          class="date-input"
          :class="{ 'invalid': !isValidYear && !isYearFocused && yearInput !== '', 'focused': isYearFocused }"
          maxlength="4"
          @focus="onFocus('Year')"
          @blur="onBlur('Year')"
          @input="onInput('year', $event)"
          ref="yearInput">
      <span class="title3">년</span>
    </div>
    <div class="date-input-set">
      <input
          v-model="monthInput"
          type="tel"
          placeholder="00"
          class="date-input"
          :class="{ 'invalid': !isValidMonth && !isMonthFocused && monthInput !== '', 'focused': isMonthFocused }"
          maxlength="2"
          @focus="onFocus('Month')"
          @blur="onBlur('Month')"
          @input="onInput('month', $event)"
          ref="monthInput">
      <span class="title3">월</span>
    </div>
    <div class="date-input-set">
      <input
          v-model="dayInput"
          type="tel"
          placeholder="00"
          class="date-input"
          :class="{ 'invalid': !isValidDay && !isDayFocused && dayInput !== '', 'focused': isDayFocused }"
          maxlength="2"
          @focus="onFocus('Day')"
          @blur="onBlur('Day')"
          @input="onInput('day', $event)"
          ref="dayInput">
      <span class="title3">일</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "DateInput",
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    inputItem: {
      type: Object,
      required: true
    },
    dontFocus: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      yearInput: '',
      monthInput: '',
      dayInput: '',
      internalDate: '0000-00-00',
      isValidYear: true,
      isValidMonth: true,
      isValidDay: true,
      isYearFocused: false,
      isMonthFocused: false,
      isDayFocused: false,
      hasWarnedInvalid: false,
      isCompleted: false
    }
  },
  emits: ['update:modelValue', 'focus', 'submitAnswer', 'warn', 'invalidDate'],
  watch: {
    modelValue: {
      handler(newValue) {
        if (newValue && newValue.match(/^\d{4}-\d{2}-\d{2}$/)) {
          const [year, month, day] = newValue.split('-');
          this.yearInput = year;
          this.monthInput = month;
          this.dayInput = day;
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.modelValue && this.modelValue.match(/^\d{4}-\d{2}-\d{2}$/)) {
        const [year, month, day] = this.modelValue.split('-');
        this.yearInput = year;
        this.monthInput = month;
        this.dayInput = day;
      }
      if(!this.dontFocus){
        this.$refs.yearInput.focus();
      }
    });
  },
  methods: {
    onFocus(field) {
      this[`is${field}Focused`] = true;
      this.$emit('focus');
    },
    onInput(field, event) {
      let value = this.filterNonNumeric(event.target.value);

      if (field === 'year' && value.length === 4) {
        this.$refs.monthInput.focus();
      } else if (field === 'month') {
        if (value.length === 1 && parseInt(value) > 1) {
          value = '0' + value;
          this.$refs.dayInput.focus();
        } else if (value.length === 2) {
          this.$refs.dayInput.focus();
        }
      } else if (field === 'day') {
        if (value.length === 1 && parseInt(value) >= 4) {
          value = '0' + value;
          this.$refs.dayInput.blur();
        } else if (value.length === 2) {
          this.$refs.dayInput.blur();
        }
      }

      this[`${field}Input`] = value;
      this.validateAndHandle(false);
    },

    onBlur(field) {
      this[`is${field}Focused`] = false;

      if (field === 'Month') {
        if (this.monthInput.length === 1) {
          this.monthInput = this.monthInput.padStart(2, '0');
        }
      } else if (field === 'Day') {
        if (this.dayInput.length === 1) {
          this.dayInput = this.dayInput.padStart(2, '0');
        }
      }

      this.validateAndHandle(true);
    },
    filterNonNumeric(value) {
      return value.replace(/[^0-9]/g, '');
    },
    validateAndHandle(shouldWarn = false) {
      if (!this.yearInput && !this.monthInput && !this.dayInput) {
        this.resetValidationState();
        return;
      }

      const year = this.yearInput;
      const month = this.monthInput;
      const day = this.dayInput;

      this.isValidYear = this.validateYear(year);
      this.isValidMonth = this.validateMonth(month);
      this.isValidDay = this.validateDay(year, month, day);

      const isFullyEntered = year.length === 4 && month.length === 2 && day.length === 2;
      const isValidDate = this.isValidYear && this.isValidMonth && this.isValidDay;

      if (isFullyEntered) {
        if (isValidDate) {
          this.submitValidDate(year, month, day);
        } else if (shouldWarn) {
          this.handleInvalidDate();
        }
      } else if (shouldWarn) {
        // 부분적으로 입력되었고, 각 필드가 유효하지 않을 때만 경고
        if ((year && !this.isValidYear) || (month && !this.isValidMonth) || (day && !this.isValidDay)) {
          this.$emit('warn', '올바른 날짜를 입력해주세요');
          this.hasWarnedInvalid = true;
        }
      }
    },
    resetValidationState() {
      this.isValidYear = true;
      this.isValidMonth = true;
      this.isValidDay = true;
      this.hasWarnedInvalid = false;
    },
    submitValidDate(year, month, day) {
      year = year.padStart(4, '0');
      month = month.padStart(2, '0');
      day = day.padStart(2, '0');

      this.internalDate = `${year}-${month}-${day}`;
      this.isCompleted = true;
      this.$emit('update:modelValue', this.internalDate);
      this.$emit('submitAnswer', {
        ...this.inputItem,
        value: this.internalDate,
        inputFormType: this.inputItem.inputFormType,
        inputFormSubtype: this.inputItem.inputFormSubtype
      });
      this.hasWarnedInvalid = false;
    },
    handleInvalidDate() {
      this.isCompleted = false;
      this.$emit('update:modelValue', '');
      this.$emit('invalidDate');
      if (!this.hasWarnedInvalid) {
        this.$emit('warn', '올바른 날짜를 입력해주세요');
        this.hasWarnedInvalid = true;
      }
    },
    validateYear(year) {
      const currentYear = new Date().getFullYear();
      return year >= 1900 && year <= currentYear;
    },
    validateMonth(month) {
      return month >= 1 && month <= 12;
    },
    validateDay(year, month, day) {
      if (!year || !month || !day) return false;
      const daysInMonth = new Date(year, month, 0).getDate();
      return day >= 1 && day <= daysInMonth;
    }
  }
}
</script>

<style scoped>
input{
  font-family: 'SUIT Variable', sans-serif;
}

.date-input-container {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  gap: 20px;
  align-items: center;
}

.date-input-set {
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: center;
  align-items: center;
}

.date-input {
  padding: 16px;
  box-sizing: border-box;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 700;
  background: var(--Grayscale-Gray11, #F5F5F5);
  border: none;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.date-input-set:nth-child(2) .date-input {
  width: 84px;
}

/* 월과 일 입력 필드를 위한 스타일 */
.date-input-set:nth-child(3) .date-input,
.date-input-set:nth-child(4) .date-input {
  width: 60px;
}



.date-input::placeholder {
  font-size: 18px;
  color: var(--Grayscale-Gray8, #8C8C8C);
}

.title3 {
  white-space: nowrap;
  margin-left: 4px;
}

.alert {
  position: absolute;
  top: -24px;
  right: 0;

  color: var(--Color-palette-System-color-Red);
  font-weight: bold;
  font-size: 12px;
}

.date-input.invalid {
  outline: 2px solid var(--Color-palette-System-color-Red);
}

.date-input:focus {
  outline: 2px solid var(--Color-palette-Primary-Default);
}

.date-input.focused {
  outline: 2px solid var(--Color-palette-Primary-Default);
}

</style>