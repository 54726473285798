<template>
  <div class="component-container" v-safe-area-top
       :style="{
      padding: padding,
      backgroundImage: `url(${tarotBoxHistory.output_page_meta_data.background_image_url})`,
      backgroundColor: backgroundColor}">
    <div class="content-wrapper">
      <div class="heading-texts">
        <img id="symbol" :src="tarotBoxHistory.output_page_meta_data.objet_icon_image_url" alt="symbol"/>
        <span class="font14 gray5">
          <strong>Chapter {{ currentChapterIndex }}</strong>
        </span>
        <span class="serif gray1 font20">{{ currentPageData.page_title }}</span>
      </div>
      <div class="tarot-info" v-if="currentPageData.page_content.related_tarot_card_info.length > 0">
        <div class="tarot-border">
        </div>
        <div v-for="(card, index) in currentPageData.page_content.related_tarot_card_info"
             :key="index"
             class="tarot-card-section">
          <TarotLine v-if="index === 0" fillClass="svg-fill-gray9"/>
          <div class="tarot-card-infos">
            <ShimmerImage :src="card.card_img_url"
                          :heightRatio="136/85"
                          :borderRadius="'3px'"
                          :imageStyle="{
                                width: '23vw',
                                '-webkit-filter': 'drop-shadow(5px 5px 5px rgba(0,0,0,0.2))',
                                'filter': 'drop-shadow(5px 5px 5px rgba(0,0,0,0.2))'}"/>
            <div class="tarot-card-texts">
              <div class="bold gray2">{{card.card_name}}</div>
              <div class="tarot-card-meaning">
                <div class="bold gray4 font14">{{$t('result_meaning_text')}}</div>
                <div class="font12 gray4" style="word-break: keep-all">{{card.meaning}}</div>
              </div>
              <div class="tarot-card-meaning">
                <div class="bold gray4 font14">{{$t('result_symbol_text')}}</div>
                <div class="font12 gray4">{{card.sign}}</div>
              </div>
            </div>
          </div>
          <TarotLine fillClass="svg-fill-gray9"/>
        </div>
      </div>
      <div class="white-space" style="height: 24px; width: 100%;" v-else></div>
      <div class="content-texts serif gray4" v-html="formattedContent"></div>
    </div>
  </div>
</template>
<script>
import ShimmerImage from "@/tarot_box/components/shimmer/ShimmerImage.vue";
import TarotLine from "@/tarot_box/pages/box_result_v2/assets/detail-page/TarotLine.vue";
import { marked } from 'marked';

export default {
  name: "AnswerComponent",
  components: {ShimmerImage, TarotLine},
  props: {
    currentIndex: {
      type: Number,
    },
    currentPageData: {
      type: Object,
    },
    backgroundColor: {
      type: String,
    },
    tarotBoxHistory: {
      type: Object,
    },
    currentChapterIndex: {
      type: Number,
    },
    isShare: {
      type: Boolean,
    },
  },
  emits: ['open-card-info'],
  data() {
    return {
    };
  },
  computed: {
    formattedContent() {
      let content = this.currentPageData.page_content.content;
      content = content.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
      content = content.replace(/\*(.*?)\*/g, '<em>$1</em>');
      content = marked(content);

      // 변환된 HTML 파싱
      const tempElement = document.createElement('div');
      tempElement.innerHTML = content;

      // 모든 <p> 요소에 gray4 클래스 추가
      const paragraphs = tempElement.getElementsByTagName('p');
      for (let i = 0; i < paragraphs.length; i++) {
        paragraphs[i].classList.add('gray4');
      }

      // 수정된 HTML 반환
      return tempElement.innerHTML;
    },
    padding() {
      return this.isShare ? '48px 24px 200px 24px' : '100px 24px 200px 24px';
    }
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style scoped>

p{
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 26px !important;
  .gray4{
    font-size: 16px !important;
  }
}

.tarot-card-section{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}


.tarot-card-infos{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 30px 12px;
  box-sizing: border-box;
  gap: 20px;
}

.tarot-card-texts{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: calc(23vw / 85 * 136);
  width: 60%;
  text-align: left;
  padding-right: 30px;
  box-sizing: border-box;
}

.tarot-info{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 40px;
}

.tarot-border{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100% / 111 * 2);
  margin: 0 auto;
  img{
    width: 100%;
  }
}

.component-container {
  width: 100vw;
  min-height: 100vh;
  background-size: 100% auto;
  background-position: bottom;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  overscroll-behavior: none;
  -webkit-overflow-scrolling: auto;
}

.content-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  overscroll-behavior: none;
  -webkit-overflow-scrolling: auto;
}

.heading-texts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 6px;
}

#symbol {
  height: 30px;
}

.content-texts {
  text-align: left;
  line-height: 26px;
  padding-top: 24px;
  box-sizing: border-box;
  margin-bottom: 24px;
}


</style>