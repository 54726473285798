//cdn 이미지 처리 유틸리티
const getProcessedImageUrl = (url, options = {}) => {
    // Cloudflare Images URL 패턴 확인
    const isCloudflareUrl = url.includes('imagedelivery.net')
    const isFirebaseUrl = url.includes('firebasestorage.googleapis.com')

    if (!isCloudflareUrl || isFirebaseUrl) {
        return url
    }

    // URL의 마지막 세그먼트(variant)를 제외한 기본 URL 생성
    const urlParts = url.split('/')
    const baseUrl = urlParts.slice(0, -1).join('/')
    const params = []

    // 기본 옵션 처리
    if (options.width) {
        params.push(`w=${options.width}`)
    }

    if (options.quality) {
        params.push(`q=${options.quality}`)
    } else {
        params.push('q=50') // 기본 품질
    }

    // 추가 옵션 처리
    if (options.blur && options.blur > 0) {
        params.push(`blur=${Math.min(250, Math.max(1, options.blur))}`)
    }

    if (options.dpr && options.dpr > 1) {
        params.push(`dpr=${Math.min(3, Math.max(1, options.dpr))}`)
    }

    if (options.format) {
        params.push(`f=${options.format}`)
    }

    if (options.fit) {
        params.push(`fit=${options.fit}`)
    }

    if (options.metadata) {
        params.push(`metadata=${options.metadata}`)
    }

    if (options.sharpen) {
        params.push(`sharpen=${Math.min(10, Math.max(1, options.sharpen))}`)
    }

    if (options.trim) {
        params.push('trim=true')
    }

    return `${baseUrl}/${params.join(',')}`
}

/**
 * 자주 사용하는 이미지 프리셋
 */
const ImagePresets = {
    thumbnail: {
        width: 320,
        quality: 70,
        format: 'auto'
    },
    content: {
        width: 960,
        quality: 80,
        format: 'auto'
    },
    fullWidth: {
        width: 1920,
        quality: 85,
        format: 'auto'
    },
    retinaContent: {
        width: 960,
        quality: 80,
        dpr: 2,
        format: 'auto'
    },
    blurredThumbnail: {
        width: 320,
        quality: 60,
        blur: 100,
        format: 'auto'
    }
}

// 이미지 측정 함수
const measureImagePerformance = (src) => {
    const startTime = performance.now()
    const img = new Image()

    return new Promise((resolve) => {
        img.onload = () => {
            const loadTime = performance.now() - startTime

            fetch(src)
                .then(response => {
                    const size = parseInt(response.headers.get('content-length') || '0')
                    resolve({
                        src,
                        loadTime: Math.round(loadTime),
                        size: size ? Math.round(size / 1024) : 'N/A'
                    })
                })
                .catch(() => {
                    resolve({
                        src,
                        loadTime: Math.round(loadTime),
                        size: 'N/A'
                    })
                })
        }

        img.onerror = () => {
            resolve({
                src,
                loadTime: -1,
                size: 'N/A'
            })
        }

        img.src = src
    })
}

// 모든 이미지 측정
const measureAllImages = async (imageUrls) => {
    console.group('All Images Loading Performance')
    console.log(`Total Images to Load: ${imageUrls.length}`)

    const startTime = performance.now()
    const results = await Promise.all(
        imageUrls.map(url => measureImagePerformance(url))
    )
    const actualLoadTime = performance.now() - startTime

    // 결과 정렬 (로딩 시간 기준)
    results.sort((a, b) => b.loadTime - a.loadTime)

    // 테이블 형태로 출력
    console.table(results.map(result => ({
        'URL': result.src,
        'Load Time (ms)': result.loadTime,
        'Size (KB)': result.size
    })))

    // 전체 통계
    const totalLoadTime = results.reduce((sum, result) => sum + result.loadTime, 0)
    const totalSize = results.reduce((sum, result) =>
        sum + (typeof result.size === 'number' ? result.size : 0), 0)

    console.log('\nPerformance Summary:')
    console.log(`Sum of Individual Load Times: ${Math.round(totalLoadTime)}ms`)
    console.log(`Actual Total Load Time: ${Math.round(actualLoadTime)}ms`)
    console.log(`Average Load Time: ${Math.round(totalLoadTime / results.length)}ms`)
    console.log(`Max Load Time: ${Math.round(Math.max(...results.map(r => r.loadTime)))}ms`)
    console.log(`Total Size: ${Math.round(totalSize / 1024)}MB`)
    console.log(`Total Images: ${results.length}`)
    console.groupEnd()
}

// export 필요한 함수들
export { getProcessedImageUrl, ImagePresets, measureAllImages }