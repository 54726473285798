<template>
  <ShimmerImage :src="src"
                :imageStyle="{ width: '126px' }"
                :border-radius="'8px'"
                :requestWidth="200"
                :height-ratio="223/160"/>
</template>

<script>
import ShimmerImage from "@/tarot_box/components/shimmer/ShimmerImage.vue";

export default {
  name: "ThumbnailImageM",
  components: {ShimmerImage},
  props: {
    src: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped>

</style>