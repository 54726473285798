<template>
  <div class="carousel-wrapper">
    <SliderControls :currentPanelIndex="currentPanelIndex" :totalCarousel="totalCarousel"/>
    <Flicking
        ref="flicking"
        @holdEnd="handleStartSwipe"
        @changed="handlePanelChange"
        :options="{
            circular: true,
            align: 'center',
            horizontal: true,
            moveType: 'strict',
            bound: false,
            threshold: 40,
            duration: 400,
            useFractionalSize: true,
            transition: 'fade',
          }"
        :plugins="plugins"
    >
      <div v-for="(banner, index) in promotionBanners"
           :key="index"
           class="carousel-item"
           @click="onBoxSelected(banner)">
        <ShimmerImage
            :src="banner.imageUrl"
            :imageStyle="{ width: '100vw', maxWidth: '480px', maxHeight: '378.6px'}"
            :heightRatio="0.7889"
            :isCircle="false"
            :borderRadius="'0px'"
            :requestWidth="720"
        />
      </div>
    </Flicking>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ShimmerImage from "@/tarot_box/components/shimmer/ShimmerImage.vue";
import {logEvent} from "@/tarot_box/helper/native_api";
import Flicking from '@egjs/vue3-flicking';
import { AutoPlay } from '@egjs/flicking-plugins';
import SliderControls from "@/tarot_box/pages/home/components/main_carousel/SliderControls.vue";

export default {
  name: "MainCarousel",
  components: {
    SliderControls,
    ShimmerImage,
    Flicking,
  },
  inject: ["eventBus"],
  data() {
    return {
      plugins: [
        new AutoPlay({ duration: 3500, direction: 'NEXT' }),
      ],
      currentPanelIndex: 1,
      isTouching: false,
    };
  },
  props: {
    totalCarousel:{
      type: Number
    },
  },
  mounted(){
  },
  computed: {
    ...mapGetters(['user', 'tarotBoxes', 'promotionBanners', 'iconCategories', 'homeSections']),
  },
  methods: {
    onBoxSelected(banner) {
      const urlObj = new URL(banner.linkUrl, window.location.origin);
      switch(banner.linkType) {
        case 'tarot_box_detail': {
          const tarotBoxId = urlObj.searchParams.get('tarot_box_id');

          logEvent ('home_promo_banner_click', {
            "link_type": banner.linkType,
            "tarot_box_id": tarotBoxId,
            "tarot_box_name": banner.title
          });
          logEvent ('home_contents_click', {
            "tarot_box_id": tarotBoxId,
            "tarot_box_name": banner.title,
            "click_origin_name": "promotion_banner",
            "click_origin": "promotion_banner"
          })

          this.eventBus.emit('openTarotDetail', tarotBoxId);
          break;
        }
        case 'inner_link' : {
          const category = urlObj.searchParams.get('category');
          const isNumeric = /^\d+$/.test(category); // 숫자만 포함되어 있는지 검증

          let eventData = {
            "link_type": banner.linkType
          };
          if (isNumeric) {
            eventData.category_id = category;
          } else {
            eventData.category_name = category;
          }
          logEvent('home_promo_banner_click', eventData);
          this.eventBus.emit('openCategoryPage', category);
          break;
        }
        case 'outer_link' : {
          window.open(banner.linkUrl);
          logEvent ('home_promo_banner_click', {
            "link_type": banner.linkType,
            "external_link": banner.linkUrl
          });
          break;
        }
        default: {
          logEvent ('home_promo_banner_click', {});
          break;
        }
      }
    },
    handleStartSwipe() {
      const currentUrl = window.location.href;
      if (window.location.href === currentUrl) {
        this.isTouching = true;
      }
    },
    handlePanelChange(event){
      this.currentPanelIndex = event.index + 1;
      this.recordSwipe();
    },
    recordSwipe(){
      if (this.isTouching === true) {
        logEvent("home_promo_banner_swipe",{});
        this.isTouching = false;
      }
    },
  },
}
</script>

<style scoped>
.carousel-wrapper{
  position: relative;
  width: 100vw;
  max-width: 480px;
  height: calc(100vw * 0.7889);
  max-height: calc(480px * 0.7889);
  margin: 0;
  padding: 0;
  background-color: transparent;
  overflow: hidden;
}

.carousel-item{
  width: 100%;
  height: calc(100dvw * 0.7889);
}
</style>