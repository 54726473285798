<template>
  <div class="ranking-item" @click="handleDetailOpen()">
    <div class="ranking-area">
      <h1 class="title1">{{ itemIndex }}</h1>
    </div>
    <div class="thumbnail-area">
      <ShimmerImage :src="item.thumbnailImgUrl"
                    :imageStyle="{ width: '45px'}"
                    :borderRadius="'4px'"
                    :requestWidth="80"
                    :heightRatio="1.3788">
      </ShimmerImage>
    </div>
    <div class="text-area">
      <h3 class="title5" v-html="parseText(item.title)"/>
      <span class="text3">{{ item.subTitle }}</span>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ShimmerImage from "@/tarot_box/components/shimmer/ShimmerImage.vue";
import {logEvent} from "@/tarot_box/helper/native_api";
export default {
  name: "RankingSingleItems",
  inject: ["eventBus"],
  components: {
    ShimmerImage
  },
  data() {
    return {
    };
  },
  props: {
    item: Object,
    itemIndex: Number,
    selectedCategory: String
  },
  computed: {
    ...mapGetters(['tarotBoxes']),
  },
  methods: {
    parseText(text) {
      if (text) {
        return text.replace(/\\n/g, '<br>');
      } else {
        return '';
      }
    },
    handleDetailOpen() {
      logEvent('home_rank_list_click', {
        'category_name': this.selectedCategory,
        'category_rank': this.itemIndex,
        'tarot_box_id': this.item.id,
        'tarot_box_name': this.item.title
      });
      logEvent ('home_contents_click', {
        'tarot_box_id': this.item.id,
        'tarot_box_name': this.item.title,
        "click_origin_name": this.selectedCategory,
        'click_origin': 'ranking'});
      this.eventBus.emit('openTarotDetail', this.item.id);
    }
  }
}

</script>

<style scoped>
.ranking-item {
  display: grid;
  grid-template-columns: 30px 45px auto; /* 각 영역의 너비 설정 */
  gap: 8px; /* 영역 사이의 간격 */
  align-items: center;
  padding: 12px;
  width: 75vw;
  max-width: 350px;
  height: 62px;
  border: 0.5px solid var(--Grayscale-Gray10);
  border-radius: 8px;
  background-color: var(--Grayscale-Gray12);
  margin-right: 12px;
  margin-bottom: 10px;
  box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.08);
}
.ranking-area, .thumbnail-area {
  margin-right: 8px;
}

.ranking-area {
  width: 30px;
}

.thumbnail-area {
  width: 45px;
}

.text-area {
  display: flex;
  flex-direction: column;
  text-align: left;
  overflow: hidden; /* 말줄임표 표시를 위해 필요 */
}

.title5 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-all;
  margin-bottom: 8px;
  margin-top: 0;
}

.text3 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>