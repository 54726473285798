<template>
  <div class="mysaju-create-page">
    <header>
      <div style="width: 24px; height: 24px; display: flex; align-items: center; justify-content: center;" @click="goBack">
        <img v-if="!isTeleported" :src="require('@/my_page/assets/v2/exit-icon.svg')"  alt=""/>
        <img v-else :src="require('@/my_page/assets/x-button.svg')"  alt=""/>
      </div>
      <span>{{ headerText }}</span>
      <div style="width: 24px; height: 24px; display: flex; align-items: center; justify-content: center;"/>
    </header>
    <div class="proceed-button-wrapper" v-safe-area-bottom
         :class="{ 'show-button': showProceedButton }"
         v-if="!isCheckCompOpened && !isPageChanging">
      <div v-wave
           :class=" allCompleted ? 'proceed-button' : 'proceed-button-disabled'"
           @click="createNewSaju">완료</div>
    </div>
    <div class="detail-wrapper" v-safe-area-bottom>
      <div class="content-container">
        <BottomSheet
            :user-birth-infos="userBirthInfos"
            ref="bottomSheet"
            @user-saju-select="handleUserSajuSelect"
            @select="handleBottomSheetSelect"
            @keyup.stop="handleKeyup"
        />
        <div class="detail-container">
          <div class="sections" :style="{ visibility: isCheckCompOpened ? 'hidden' : 'visible'}">
            <transition-group name="list" tag="div" class="section-items" mode="out-in">
              <div
                  v-for="(inputItem, index) in visibleItems"
                  :key="`${inputItem.key}-${inputItem.inputFormType}-${index}`"
                  class="item"
                  :class="{ 'first-item': index === 0 }"
              >
                <span class="title5 gray5"
                      v-if="inputItem.inputFormType !== 'surveyScore'">{{ inputItem.inputFormHeaderLabel }}</span>
                <TextInput
                    v-if="inputItem.inputFormType === 'textInput'"
                    v-model="inputItem.tempValue"
                    :input-item="inputItem"
                    @submitAnswer="submitAnswer"
                    @focus="activateItem(inputItem)"
                />
                <NumberInput
                    v-else-if="inputItem.inputFormType === 'numberInput'"
                    v-model="inputItem.tempValue"
                    :input-item="inputItem"
                    @submitAnswer="submitAnswer"
                    @focus="activateItem(inputItem)"
                />
                <DateInput
                    v-else-if="inputItem.inputFormType === 'dateInput'"
                    v-model="inputItem.value"
                    :input-item="inputItem"
                    @warn="handleWarn"
                    @submitAnswer="submitAnswer"
                    @invalidDate="handleInvalidDate"
                    @focus="activateItem(inputItem)"
                />
                <GenderInput
                    v-else-if="inputItem.inputFormType === 'genderInput'"
                    v-model="inputItem.value"
                    :input-item="inputItem"
                    @submitAnswer="submitAnswer"
                    @focus="activateItem(inputItem)"
                />
                <LongTextField
                    v-else-if="inputItem.inputFormType === 'longTextInput'"
                    v-model="inputItem.tempValue"
                    :input-item="inputItem"
                    @submitAnswer="submitAnswer"
                    @focus="activateItem(inputItem)"
                />
                <SurveyScore
                    v-else-if="inputItem.inputFormType === 'surveyScore'"
                    v-model="inputItem.tempValue"
                    :input-item="inputItem"
                    :key="`survey-${inputItem.key}-${inputItem.inputFormSubtype}`"
                    @submitAnswer="submitAnswer"
                    @focus="activateItem(inputItem)"
                />
                <!-- timeInput은 BottomSheet를 사용하므로 기타 타입으로 처리 -->
                <div
                    v-else
                    class="select-item"
                    :class="{ 'active': inputItem.isActive, 'completed': inputItem.isCompleted }"
                    @click="activateItem(inputItem)"
                >
                  <span class="title3">
                    {{ inputItem.isCompleted ? inputItem.value : inputItem.inputFormHeaderLabel }}
                  </span>
                  <img :src="require('@/tarot_box/pages/box_input_v2/assets/Input/select.svg')" alt="select"/>
                </div>
              </div>
            </transition-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from "@/tarot_box/pages/box_input_v2/components/Inputs/TextInput.vue";
import DateInput from "@/tarot_box/pages/box_input_v2/components/Inputs/DateInput.vue";
import GenderInput from "@/tarot_box/pages/box_input_v2/components/Inputs/GenderInput.vue";
import BottomSheet from "@/tarot_box/pages/box_input_v2/components/BottomSheets/BottomSheet.vue";
import LongTextField from "@/tarot_box/pages/box_input_v2/components/Inputs/LongTextInput.vue";
import NumberInput from "@/tarot_box/pages/box_input_v2/components/Inputs/NumberInput.vue";
import SurveyScore from "@/tarot_box/pages/box_input_v2/components/Inputs/SurveyScore.vue";
import {hideBottomBar, logEvent} from "@/tarot_box/helper/native_api";
import moment from "moment";
import {createBirthInfo} from "@/tarot_box/helper/mytarot_api";

import _debounce from 'lodash/debounce';

export default {
  name: "MySajuCreatePage",
  components: {
    TextInput,
    DateInput,
    GenderInput,
    BottomSheet,
    LongTextField,
    NumberInput,
    SurveyScore,
  },
  props: {
    isTeleported: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputSections: [
        {
          sectionType: "personalInfo",
          inputs: [
            {
              inputFormType: "textInput",
              inputFormSubtype: "name",
              inputFormHeaderLabel: "이름",
              inputFormQuestion: "",
              key: "name",
              tempValue: "",
              value: "",
              isCompleted: false,
              isActive: false,
              options: { placeholder: "이름을 입력하세요" },
            },
            {
              inputFormType: "genderInput",
              inputFormSubtype: "gender",
              inputFormHeaderLabel: "성별",
              inputFormQuestion: "",
              key: "gender",
              value: "",
              isCompleted: false,
              isActive: false,
              options: {},
            },
            {
              inputFormType: "dateInput",
              inputFormSubtype: "birthdate",
              inputFormHeaderLabel: "생년월일",
              inputFormQuestion: "",
              key: "date",
              value: "",
              isCompleted: false,
              isActive: false,
              options: {},
            },
            {
              inputFormType: "timeInput",
              inputFormSubtype: "birthtime",
              inputFormHeaderLabel: "태어난 시각",
              inputFormQuestion: "",
              key: "time",
              value: "",
              isCompleted: false,
              isActive: false,
              options: { is24HourFormat: true },
            },
          ],
        },
      ],
      // 재사용 데이터 (원래 코드와 동일)
      userAnswers: [],
      stringUserAnswers: "",
      sajuData: [],
      partnerSajuData: [],
      isCheckCompOpened: false,
      isPageChanging: false,
      openSajuInfoPopup: false,
      cachedInputSections: [],
      cachedCurrentIndex: 0,
      skipTransition: false,
      showProceedButton: false,
      presetNameForHeader: {
        personalInfo: "",
        partnerInfo: "",
      },
      isPersonalInfoFirstAutoFilled: false,
      isPartnerInfoFirstAutoFilled: false,
      currentIndex: 1,
      userName: "고객",
      userBirthInfos: [],
      isWeb: false,
      tarotBoxData: {},
      isLoading: false,
      paddingTop: new URLSearchParams(window.location.search).get('inset_top') || 0,
    };
  },
  mounted() {
    hideBottomBar()
    if(this.isTeleported === false){
      logEvent('mypage_sajulist_myinfo_into',{})
    }
    logEvent('saju_add_into',{})
    window.onBackButtonClick = this.onBackButtonClick;
  },
  beforeUnmount() {
    window.onBackButtonClick = null;
  },
  computed: {
    visibleItems() {
      const currentSection = this.inputSections[this.currentIndex - 1];
      if (!currentSection) return [];
      const items = currentSection.inputs;
      const completedItems = items.filter((item) => item.isCompleted);
      const incompleteItems = items.filter((item) => !item.isCompleted);
      if (incompleteItems.length > 0) {
        return [...completedItems, incompleteItems[0]];
      } else {
        return completedItems;
      }
    },
    headerText() {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get('new_saju') === 'true' ? '내 사주정보 입력' : '사주정보 추가';
    },
    allCompleted() {
      return this.visibleItems.every((item) => item.isCompleted);
    },
  },
  watch: {
    currentIndex() {
      this.showProceedButton = false;
      setTimeout(() => {
        this.showProceedButton = true;
      }, 1000);
      if (this.currentIndex < this.totalIndex) {
        let sectionType = this.inputSections[this.currentIndex - 1].sectionType;
        let convertedSectionType;
        if (sectionType === "personalInfo") {
          convertedSectionType = "myinfo";
        } else if (sectionType === "partnerInfo") {
          convertedSectionType = "otherinfo";
        } else if (sectionType === "survey") {
          convertedSectionType = "survey";
        } else {
          convertedSectionType = "additional";
        }
        logEvent(`boxinput_${convertedSectionType}_into`, {
          tarot_box_id: this.tarotBoxData.id,
          tarot_box_name: this.tarotBoxData.title,
        });
      }
      if (
          !this.userBirthInfos ||
          !Array.isArray(this.userBirthInfos) ||
          this.userBirthInfos.length === 0
      )
        return;
      const currentSection = this.inputSections[this.currentIndex - 1];
      if (!currentSection) return;
      if (
          currentSection.sectionType === "personalInfo" &&
          !this.isPersonalInfoFirstAutoFilled
      ) {
        const preset = this.userBirthInfos.find(
            (obj) => obj.is_user_birth_info === true
        );
        if (preset) {
          this.applyPresetData(preset);
          this.isPersonalInfoFirstAutoFilled = true;
        }
      } else if (
          currentSection.sectionType === "partnerInfo" &&
          !this.isPartnerInfoFirstAutoFilled
      ) {
        const partnerPresets = this.userBirthInfos.filter(
            (obj) => obj.is_user_birth_info === false
        );
        if (partnerPresets.length > 0) {
          partnerPresets.sort((a, b) => b.id - a.id);
          this.applyPresetData(partnerPresets[0]);
          this.isPartnerInfoFirstAutoFilled = true;
        }
      }
    },
  },
  methods: {
    onBackButtonClick() {
      this.goBack();
    },
    activateItem(item) {
      this.inputSections[this.currentIndex - 1].inputs.forEach((input) => {
        input.isActive = false;
        input.tempValue = input.value || "";
      });
      item.isActive = true;
      if (
          ["multiChoiceGrid", "timeInput"].includes(item.inputFormType)
      ) {
        console.log("BottomSheet opened");
        this.$nextTick(() => {
          this.$refs.bottomSheet.open(item);
        });
      }
    },
    handleInvalidDate() {
      if (this.activeItem && !this.activeItem.isCompleted) {
        this.activeItem.isCompleted = false;
      }
    },
    moveToNextItem() {
      const currentSection = this.inputSections[this.currentIndex - 1];
      const nextIncompleteItem = currentSection.inputs.find(
          (item) => !item.isCompleted
      );
      if (nextIncompleteItem) {
        if (
            ["timeInput", "multiChoiceGrid"].includes(
                nextIncompleteItem.inputFormType
            )
        ) {
          this.openBottomSheet(nextIncompleteItem);
        }
        if (nextIncompleteItem.inputFormType !== "surveyScore") {
          this.activateItem(nextIncompleteItem);
        }
      } else {
        console.log('no more items');
      }
    },
    submitAnswer(item) {
      if (item.value !== undefined && item.value !== "") {
        this.updateAnswer(item, item.value);
        console.log("Item submitted:", item);
        if (!this.allCompleted) {
          if (
              ["multiChoiceGrid", "timeInput"].includes(item.inputFormType)
          ) {
            setTimeout(() => {
              this.moveToNextItem();
            }, 700);
          } else {
            setTimeout(() => {
              this.moveToNextItem();
            }, 700);
          }
        }
      } else if (item.tempValue && item.tempValue !== "") {
        this.updateAnswer(item, item.tempValue);
      }
    },
    updateAnswer(item, value) {
      const updatedItem = this.inputSections[this.currentIndex - 1].inputs.find(
          (input) =>
              input.key === item.key &&
              input.inputFormType === item.inputFormType &&
              input.inputFormSubtype === item.inputFormSubtype
      );
      if (updatedItem) {
        updatedItem.value = value;
        if (!updatedItem.isCompleted) {
          updatedItem.isCompleted = true;
        }
        updatedItem.isActive = false;
      }
      const existingAnswerIndex = this.userAnswers.findIndex(
          (answer) =>
              answer.key === item.key &&
              answer.inputFormType === item.inputFormType &&
              answer.inputFormSubtype === item.inputFormSubtype
      );
      if (existingAnswerIndex !== -1) {
        this.userAnswers[existingAnswerIndex] = {
          key: item.key,
          value: value,
          inputFormType: item.inputFormType,
          inputFormSubtype: item.inputFormSubtype,
        };
      } else {
        this.userAnswers.push({
          key: item.key,
          value: value,
          inputFormType: item.inputFormType,
          inputFormSubtype: item.inputFormSubtype,
        });
      }
      console.log('User answers:', this.userAnswers);
    },
    openBottomSheet(item) {
      this.activateItem(item);
      this.$refs.bottomSheet.open(item);
    },
    handleBottomSheetSelect(selectedData) {
      const item = this.inputSections[this.currentIndex - 1].inputs.find(
          (input) =>
              input.key === selectedData.key &&
              input.inputFormType === selectedData.inputFormType &&
              input.inputFormSubtype === selectedData.inputFormSubtype
      );
      if (item) {
        this.submitAnswer({
          ...item,
          value: selectedData.value,
          inputFormType: selectedData.inputFormType,
          inputFormSubtype: selectedData.inputFormSubtype,
        });
      }
    },
    handleWarn(message) {
      this.$emit("warn", message);
    },
    handleKeyup(event) {
      console.log("Keyup event:", event);
    },
    updateUserAnswers() {
      const nonSurveyAnswers = this.userAnswers
          .filter(
              (answer) =>
                  !["saju", "saju_partner"].includes(answer.key) &&
                  answer.inputFormType !== "surveyScore"
          )
          .map(({ key, value }) => ({ key, value }));
      const surveyScores = this.userAnswers.filter(
          (answer) => answer.inputFormType === "surveyScore"
      );
      const consolidatedScores = {};
      surveyScores.forEach((answer) => {
        if (!consolidatedScores[answer.key]) {
          consolidatedScores[answer.key] = 0;
        }
        consolidatedScores[answer.key] += parseInt(answer.value);
      });
      const consolidatedSurveyAnswers = Object.entries(consolidatedScores).map(
          ([key, value]) => ({
            key,
            value: value.toString(),
          })
      );
      let updatedUserAnswers = [
        ...nonSurveyAnswers,
        ...consolidatedSurveyAnswers,
      ];
      if (
          this.presetNameForHeader &&
          this.presetNameForHeader.personalInfo
      ) {
        console.log(
            "Preset name for header:",
            this.presetNameForHeader.personalInfo
        );
        updatedUserAnswers = updatedUserAnswers.filter(
            (answer) =>
                answer.key !== "name" && answer.key !== "my_name"
        );
        updatedUserAnswers.push({
          key: "name",
          value: this.presetNameForHeader.personalInfo,
        });
        updatedUserAnswers.push({
          key: "my_name",
          value: this.presetNameForHeader.personalInfo,
        });
      }
      this.stringUserAnswers = JSON.stringify(updatedUserAnswers);
      console.log("Updated User Answers:", updatedUserAnswers);
      this.$emit("prepare", this.stringUserAnswers);
    },
    openUserSajuSelectPopup() {
      this.$refs.bottomSheet.open({
        inputFormType: "userBirthInfoSelection",
        key: "saju",
        value: "",
        inputFormHeaderLabel: "사주를 선택해주세요",
        inputFormQuestion: "사주를 선택해주세요",
        options: [{ value: "양력" }, { value: "음력" }],
      });
    },
    applyPresetData(preset) {
      if (!preset) return;
      const m = moment(preset.birth_datetime);
      const presetDate = m.format("YYYY-MM-DD");
      const presetTimeRaw = m.format("HH:mm");
      const presetTime = `${presetTimeRaw.split(":")[0]}시 ${presetTimeRaw.split(":")[1]}분`;
      const presetName = preset.name;
      const presetGender = preset.gender;
      const currentSection = this.inputSections[this.currentIndex - 1];
      if (!currentSection) return;
      if (currentSection.sectionType === "personalInfo") {
        currentSection.inputs.forEach((input) => {
          if (
              input.inputFormType === "dateInput" &&
              input.key === "saju"
          ) {
            input.value = presetDate;
            input.isCompleted = true;
          } else if (
              input.inputFormType === "timeInput" &&
              input.key === "saju"
          ) {
            input.value = presetTime;
            input.isCompleted = true;
          } else if (
              input.inputFormType === "genderInput" &&
              input.key === "saju"
          ) {
            input.value = presetGender;
            input.isCompleted = true;
          }
        });
        this.presetNameForHeader.personalInfo = presetName;
      } else if (currentSection.sectionType === "partnerInfo") {
        currentSection.inputs.forEach((input) => {
          if (
              input.inputFormType === "textInput" &&
              input.key === "partner_name"
          ) {
            input.value = presetName;
            input.tempValue = presetName;
            input.isCompleted = true;
          } else if (
              input.inputFormType === "dateInput" &&
              input.key === "saju_partner"
          ) {
            input.value = presetDate;
            input.isCompleted = true;
          } else if (
              input.inputFormType === "timeInput" &&
              input.key === "saju_partner"
          ) {
            input.value = presetTime;
            input.isCompleted = true;
          } else if (
              input.inputFormType === "genderInput" &&
              input.key === "saju_partner"
          ) {
            input.value = presetGender;
            input.isCompleted = true;
          }
        });
        this.presetNameForHeader.partnerInfo = presetName;
      }
      currentSection.inputs.forEach((input) => {
        if (input.isCompleted) {
          this.updateAnswer(input, input.value);
        }
      });
      this.updateUserAnswers();
    },
    async handleUserSajuSelect(data) {
      console.log("Preset selected via handleUserSajuSelect:", data);
      this.applyPresetData(data);
    },
    createNewSaju: _debounce(async function() {
      this.isLoading = true;
      const urlParams = new URLSearchParams(window.location.search);
      const tarotUserId = urlParams.get("tarot_user_id");
      const phoneNumber = urlParams.get("phone_number");
      const isNewSaju = urlParams.get("new_saju") === "true" || false;

      // Find date and time entries
      const dateEntry = this.userAnswers.find(answer => answer.key === "date");
      const timeEntry = this.userAnswers.find(answer => answer.key === "time");

      // Process time string (convert "07시 00분" to "07:00")
      const timeString = timeEntry.value.replace('시 ', ':').replace('분', '');

      // Combine date and time
      const birthDateTime = moment(`${dateEntry.value} ${timeString}`, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD HH:mm");

      // Get name and gender
      const name = this.userAnswers.find(answer => answer.key === "name")?.value || "";
      const gender = this.userAnswers.find(answer => answer.key === "gender")?.value || "";

      let res = await createBirthInfo(phoneNumber, tarotUserId, birthDateTime, isNewSaju, gender, name);
      if (res.result === 'success'){
        if(this.isTeleported === false){
          logEvent('mypage_sajulist_myinfo_done',{})
        }
        logEvent('saju_add_done_click',{})
        this.isLoading = false;
        this.goBack()
      } else {
        this.isLoading = false;
        this.goBack()
      }
    }, 500, {leading: true, trailing: false}),

    goBack() {
      if(!this.isTeleported){
        this.$router.go(-1);
      } else {
        this.$emit('close')
      }
    },
  }
};
</script>

<style scoped>
.mysaju-create-page{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  max-width: 100%;
  margin: 0;
  background-color: white;
  z-index: 99;
  overflow-y: auto;
}

header{
  position: fixed;
  top:0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  z-index: 2;
  padding: calc(20px + var(--safe-area-top)) 24px 20px 24px;
  box-sizing: border-box;
}

header span{
  font-size: 18px;
  font-weight: 700;
  line-height: 120%;
}

.detail-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-top: var(--safe-area-top);
  height: calc(100% - var(--safe-area-top));
  background-color: var(--Grayscale-Gray12);
  box-sizing: border-box;
  overscroll-behavior: contain;
  overscroll-behavior-y: none;
}

.content-container {
  flex-grow: 1;
  overflow-y: auto;
  padding-bottom: 54px;
  box-sizing: border-box;
  overscroll-behavior: contain;
  overscroll-behavior-y: none;
}

.proceed-button-wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 24px;
  box-sizing: border-box;
  background-color: white;
  gap: 8px;
  z-index: 30;
}

.proceed-button-wrapper.show-button {
  bottom: 0;
}

.proceed-button {
  display: flex;
  flex: 5;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 13px;
  background: var(--Color-palette-Primary-Default);
  color: white;
  font-size: 16px;
  font-weight: 700;
}

.proceed-button-disabled {
  display: flex;
  flex: 5;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 13px;
  color: white;
  font-size: 16px;
  font-weight: 700;
  pointer-events: none;
  background: var(--Color-palette-Primary-Lighten3, #B9C0D5);
}

.proceed-button-white {
  display: flex;
  flex: 2;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  padding: 13px;
  background: var(--Grayscale-Gray12);
  color: var(--Color-palette-Primary-Default);
  outline: 2px solid var(--Color-palette-Primary-Default);
  font-size: 16px;
  font-weight: 700;
}

.detail-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  background-color: white;
  padding: 54px 24px;
  box-sizing: border-box;
  overscroll-behavior: contain;
  overscroll-behavior-y: none;
}

.sections {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  position: relative;
}

.section-items {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 20px;
  width: 100%;
  gap: 28px;
}

.item {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
}

.title3 {
  color: var(--Grayscale-Gray8);
}

.select-item {
  width: 100%;
  padding: 13px 16px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: 2px solid var(--Grayscale-Gray10, #ECECEC);
}

.select-item.active {
  border: 2px solid var(--Grayscale-Gray2, #242424);
}

.select-item.completed .title3 {
  color: var(--Grayscale-Gray3);
}

.input-form {
  margin-top: 10px;
  display: flex;
  width: 100%;
}

.input-form input {
  flex-grow: 1;
  padding: 8px;
  border: 1px solid var(--Grayscale-Gray10);
  border-radius: 4px;
}

.input-form button {
  margin-left: 10px;
  padding: 8px 16px;
  background-color: var(--Color-palette-Primary-Default);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.confirmation-button {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  box-sizing: border-box;
  background-color: white;
}

.button {
  background: var(--Color-palette-Primary-Default);
  width: 100%;
  height: 52px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.button.disabled {
  background: var(--Color-palette-Primary-Lighten3, #B9C0D5);
  cursor: not-allowed;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-active.first-item {
  transition-delay: 0.5s;
}

.list-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}

.list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.list-move {
  transition: transform 0.5s ease;
}

.list-enter-active:not(.first-item) {
  transition-delay: 0.2s;
}

.slide-left-leave-active {
  transition: all 0.5s ease-out;
}

.slide-left-leave-to {
  transform: translateX(-100%);
}

.slide-left-enter-from,
.slide-left-enter-active,
.slide-left-enter-to {
  transition: none;
}
</style>
