<template>
  <div class="index-container" v-safe-area-top :style="{ backgroundColor: backgroundColor, padding: padding }">
    <div class="index-title gray1 serif">
      <img id="symbol" :src="tarotBoxHistory.output_page_meta_data.objet_icon_image_url" alt="symbol"/>
      {{$t('result_header_index')}}
    </div>
    <div class="index-table">
      <div v-for="(item, index) in filteredPageData" :key="index" class="index-item" @click="goPage(item.page_index)">
        <div class="item-texts">
          <span class="regular gray6 font12" style="margin-right: 20px"><strong>{{ item.chapter || `Chapter ${index + 1}` }}</strong></span>
          <span class="serif gray2 regular" style="padding-right: 10px; box-sizing: border-box; word-break: keep-all">{{ item.page_title }}</span>
        </div>
        <div class="item-index">
          <div class="number-text gray5 font14 serif">{{item.page_index}}</div>
          <img src="../assets/bottom-sheet/arrow-r.svg" alt="arrow" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {logEvent} from "@/tarot_box/helper/native_api";

export default {
  name: "OutputList",
  props: {
    backgroundColor: {
      type: String,
    },
    tarotBoxHistory: {
      type: Object,
    },
    isShare: {
      type: Boolean,
    }
  },
  mounted() {
    this.$nextTick(() => {
      logEvent('result_index_into', {})
    });
  },
  emits: ['go-page'],
  computed: {
    filteredPageData() {
      return this.tarotBoxHistory.result_page_data
          .filter(item => item.page_type === 'title_page')
          .map(item => {
            if (item.page_title === '부록') {
              return {
                ...item,
                chapter: '부록',
                page_title: '속시원한 심층풀이'
              };
            }
            return item;
          });
    },
    padding() {
      return this.isShare ? '48px 24px 100px 24px' : '100px 24px 100px 24px';
    }
  },
  methods: {
    goPage(index) {
      logEvent('result_index_click', { 'index': index })
      this.$emit('go-page', parseInt(index));
    }
  }
}
</script>

<style scoped>
.index-container{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: auto;
  box-sizing: border-box;
}

.index-title{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;

  font-size: 20px;
  font-style: normal;
  line-height: normal;
}

#symbol {
  height: 30px;
}

.index-table{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  position: relative;
  margin-top: 48px;
}

.index-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px 0 16px 16px;
  box-sizing: border-box;
  border-top: 1px solid var(--bdGray9);
  position: relative;
}

.index-item:last-child {
  border-bottom: 1px solid var(--bdGray9);
}

.index-item::before,
.index-item::after,
.index-item .item-index::before,
.index-item .item-index::after {
  content: "";
  position: absolute;
  width: 4px;
  height: 4px;
  background-color: var(--bdGray9);
  border: 1px solid var(--bdGray9);
  transform: rotate(45deg);
}

.index-item::before {
  top: -3px;
  left: -3px;
}

.index-item::after {
  top: -3px;
  right: -3px;
}

.index-item .item-index::before {
  bottom: -3px;
  left: -3px;
}

.index-item .item-index::after {
  bottom: -3px;
  right: -3px;
}

.item-texts{
  display: flex;
  flex-direction: column;
  gap: 4px;
  text-align: left;
  width: calc(100% - 40px);
}

.item-index{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.number-text{
  font-family: "SUIT Variable", sans-serif;
}

</style>