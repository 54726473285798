<template>
  <div class="detail-wrapper">
    <div class="content-container">
      <div class="proceed-button-wrapper"
           :class="{ 'show-button': showProceedButton }"
           v-if="!isCheckCompOpened && !isPageChanging">
        <div v-wave
             class="proceed-button-white"
             @click="goPrevPage">이전</div>
        <div v-wave
             :class=" allCompleted ?  'proceed-button' : 'proceed-button-disabled'"
             @click="openAnswerCheckComponent">완료</div>
      </div>
      <BottomSheet
          :user-birth-infos="userBirthInfos"
          :input-sections="inputSections"
          :current-index="currentIndex"
          ref="bottomSheet"
          @user-saju-select="handleUserSajuSelect"
          @select="handleBottomSheetSelect"
          @keyup.stop="handleKeyup"
          @open-create="openSajuCreatePage"
          @open-edit="openSajuEditPage"
      />
      <SajuInfoPopup v-if="openSajuInfoPopup" @close-popup="openSajuInfoPopup = false"/>
      <transition :name="skipTransition ? '' : 'slide-left'">
        <InputCheckComponent
            v-show="isCheckCompOpened"
            :user-answers="userAnswers"
            :user-name="presetNameForHeader"
            :input-sections="isCheckCompOpened ? inputSections : cachedInputSections"
            :saju-data="sajuData"
            :partner-saju-data="partnerSajuData"
            :current-index="isCheckCompOpened ? currentIndex : cachedCurrentIndex"
            :total-index="totalIndex"
            :tarot-box-data="tarotBoxData"
            @open-popup="openSajuInfoPopup = true"
            @proceed="goNextPage"
            @close-later="closeCheckComponentClickedLater"
            @close="closeCheckComponent"
        />
      </transition>
      <div class="white-wrapper"
           style="position: fixed; top: 0; left: 0; width: 100%; height: 100%; background-color: white; z-index: 20;"
           v-if="isPageChanging"/>
      <div class="detail-container">
        <div class="sections" :style="{ visibility: isCheckCompOpened ? 'hidden' : 'visible'}">

          <InputHeader
              :key="currentIndex"
              :user-name="userName"
              :preset-name="presetNameForHeader"
              :input-sections="inputSections"
              :current-index="currentIndex"
              :is-web="isWeb"
              :is-saju-key-included="isSajuKeyIncluded"
              :birth-infos="userBirthInfos"
              :current-section="inputSections[currentIndex - 1]"
              @open-birth-info-popup="openUserSajuSelectPopup"
          />

          <transition-group name="list" tag="div" class="section-items" mode="out-in">
            <div
                v-for="(inputItem, index) in visibleItems"
                :key="`${inputItem.key}-${inputItem.inputFormType}-${index}`"
                class="item"
                :class="{ 'first-item': index === 0 }"
            >
              <span class="title5 gray5"
                    v-if="inputItem.inputFormType !=='surveyScore'">{{ inputItem.inputFormHeaderLabel }}</span>

              <TextInput
                  v-if="inputItem.inputFormType === 'textInput'"
                  v-model="inputItem.tempValue"
                  :input-item="inputItem"
                  :dont-focus="dontFocus"
                  @submitAnswer="submitAnswer"
                  @focus="activateItem(inputItem)"
              />
              <NumberInput
                  v-else-if="inputItem.inputFormType === 'numberInput'"
                  v-model="inputItem.tempValue"
                  :input-item="inputItem"
                  @submitAnswer="submitAnswer"
                  @focus="activateItem(inputItem)"
              />
              <DateInput
                  v-else-if="inputItem.inputFormType === 'dateInput'"
                  v-model="inputItem.value"
                  :input-item="inputItem"
                  :dont-focus="dontFocus"
                  @warn="handleWarn"
                  @submitAnswer="submitAnswer"
                  @invalidDate="handleInvalidDate"
                  @focus="activateItem(inputItem)"
              />
              <GenderInput
                  v-else-if="inputItem.inputFormType === 'genderInput'"
                  v-model="inputItem.value"
                  :input-item="inputItem"
                  @submitAnswer="submitAnswer"
                  @focus="activateItem(inputItem)"
              />
              <LongTextField
                  v-else-if="inputItem.inputFormType === 'longTextInput'"
                  v-model="inputItem.tempValue"
                  :input-item="inputItem"
                  @submitAnswer="submitAnswer"
                  @focus="activateItem(inputItem)"
              />
              <SurveyScore
                  v-else-if="inputItem.inputFormType === 'surveyScore'"
                  v-model="inputItem.tempValue"
                  :input-item="inputItem"
                  :key="`survey-${inputItem.key}-${inputItem.inputFormSubtype}`"
                  @submitAnswer="submitAnswer"
                  @focus="activateItem(inputItem)"/>


              <!-- 기타 타입 (BottomSheet 사용) -->
              <div
                  v-else
                  class="select-item"
                  :class="{ 'active': inputItem.isActive, 'completed': inputItem.isCompleted }"
                  @click="activateItem(inputItem)"
              >
                <span class="title3">
                  {{ inputItem.isCompleted ? inputItem.value : inputItem.inputFormHeaderLabel }}
                </span>
                <img :src="require('@/tarot_box/pages/box_input_v2/assets/Input/select.svg')" alt="select"/>
              </div>
            </div>
          </transition-group>
        </div>
      </div>
    </div>
  </div>
  <teleport to="body">
    <MySajuEditPage
        v-if="isSajuEditPageOpened"
        :is-teleported="true"
        :selected-saju="selectedSaju"
        @close="closeSajuPage"/>
    <MySajuCreatePage
        v-if="isSajuCreatePageOpened"
        :is-teleported="true"
        @close="closeSajuPage"/>
  </teleport>
</template>

<script>
import InputHeader from "@/tarot_box/pages/box_input_v2/components/InputHeader.vue";
import TextInput from "@/tarot_box/pages/box_input_v2/components/Inputs/TextInput.vue";
import DateInput from "@/tarot_box/pages/box_input_v2/components/Inputs/DateInput.vue";
import GenderInput from "@/tarot_box/pages/box_input_v2/components/Inputs/GenderInput.vue";
import BottomSheet from "@/tarot_box/pages/box_input_v2/components/BottomSheets/BottomSheet.vue";
import InputCheckComponent from "@/tarot_box/pages/box_input_v2/components/InputCheckComponent.vue";
import {convertSajuData, convertToSajuDateAndTime} from "@/tarot_box/pages/utils/convertSajuData";
import {getSajuCharacters} from "@/tarot_box/helper/mytarot_api";
import LongTextField from "@/tarot_box/pages/box_input_v2/components/Inputs/LongTextInput.vue";
import NumberInput from "@/tarot_box/pages/box_input_v2/components/Inputs/NumberInput.vue";
import SurveyScore from "@/tarot_box/pages/box_input_v2/components/Inputs/SurveyScore.vue";
import SajuInfoPopup from "@/tarot_box/pages/box_input_v2/components/Popups/SajuInfoPopup.vue";
import {logEvent} from "@/tarot_box/helper/native_api";

import MySajuEditPage from "@/my_page/components/MySajuEditPage.vue";
import MySajuCreatePage from "@/my_page/components/MySajuCreatePage.vue";

import moment from 'moment';

export default {
  name: "InputDetails",
  components: {
    SajuInfoPopup,
    NumberInput,
    LongTextField,
    GenderInput,
    InputHeader,
    TextInput,
    DateInput,
    SurveyScore,
    BottomSheet,
    InputCheckComponent,
    MySajuEditPage,
    MySajuCreatePage,
  },
  props: {
    inputSections: {
      type: Array,
    },
    currentIndex: {
      type: Number,
    },
    totalIndex: {
      type: Number,
    },
    userName: {
      type: String,
    },
    tarotBoxData: {
      type: Object,
    },
    userBirthInfos: {
      type: Array,
    },
    isWeb: {
      type: Boolean,
    },
  },
  data() {
    return {
      userAnswers: [],
      stringUserAnswers: '',
      sajuData: [],
      partnerSajuData: [],
      isCheckCompOpened: false,
      isPageChanging: false,
      openSajuInfoPopup: false,
      cachedInputSections: [],
      cachedCurrentIndex: 0,
      skipTransition: false,
      showProceedButton: false,
      presetNameForHeader: {
        personalInfo: '',
        partnerInfo: '',
      },
      selectedSaju: null,
      isPersonalInfoFirstAutoFilled: false,
      isPartnerInfoFirstAutoFilled: false,
      isSajuEditPageOpened: false,
      isSajuCreatePageOpened: false,
      isSajuKeyIncluded: false,
      dontFocus: false,
    }
  },
  computed: {
    visibleItems() {
      const currentSection = this.inputSections[this.currentIndex - 1];
      if (!currentSection) return [];

      const items = currentSection.inputs;
      const completedItems = items.filter(item => item.isCompleted);
      const incompleteItems = items.filter(item => !item.isCompleted);

      if (incompleteItems.length > 0) {
        return [...completedItems, incompleteItems[0]];
      } else {
        return completedItems;
      }
    },
    userAnswersString() {
      return JSON.stringify(this.userAnswers);
    },
    activeItem() {
      return this.visibleItems.find(item => item.isActive);
    },
    allCompleted() {
      return this.visibleItems.every(item => item.isCompleted);
    }
  },
  watch: {
    currentIndex() {
      this.showProceedButton = false;
      setTimeout(() => {
        this.showProceedButton = true;
      }, 1000);
      if (this.currentIndex < this.totalIndex){
        let sectionType = this.inputSections[this.currentIndex -1].sectionType
        let convertedSectionType;
        if (sectionType === 'personalInfo'){
          convertedSectionType = 'myinfo';
        } else if (sectionType === 'partnerInfo'){
          convertedSectionType = 'otherinfo';
        } else if (sectionType === 'survey'){
          convertedSectionType = 'survey';
        } else {
          convertedSectionType = 'additional';
        }
        logEvent(`boxinput_${convertedSectionType}_into`, {
          'tarot_box_id': this.tarotBoxData.id,
          'tarot_box_name': this.tarotBoxData.title,
        });
      }

      if (!this.userBirthInfos || !Array.isArray(this.userBirthInfos) || this.userBirthInfos.length === 0) return;

      const currentSection = this.inputSections[this.currentIndex - 1];
      if (!currentSection) return;

      if (currentSection.sectionType === 'personalInfo' && !this.isPersonalInfoFirstAutoFilled) {
        const preset = this.userBirthInfos.find(obj => obj.is_user_birth_info === true);
        if (preset) {
          this.applyPresetData(preset);
          this.isPersonalInfoFirstAutoFilled = true;
        }
      }
    },
    visibleItems: {
      handler(newItems) {
        const allCompleted = newItems.every(item => item.isCompleted);
        if (allCompleted && this.currentIndex !== 0 && this.inputSections && this.inputSections[this.currentIndex - 1]) {
          this.handleAllCompleted();
        }
      },
      deep: true
    },
  },
  emits: ['load', 'go-next', 'prev', 'answer-updated', 'warn', 'proceed', 'prepare', 'exit', 'update-saju-infos'],
  methods: {
    activateItem(item) {
      // 현재 활성화된 항목을 비활성화
      this.inputSections[this.currentIndex - 1].inputs.forEach(input => {
        input.isActive = false;
        input.tempValue = input.value || '';
      });
      // 새로운 항목을 활성화
      item.isActive = true;

      // BottomSheet 항목을 자동으로 열기
      if (['multiChoiceGrid', 'timeInput'].includes(item.inputFormType)) {
        this.$nextTick(() => {
          this.$refs.bottomSheet.open(item);
        });
      }
    },
    handleInvalidDate() {
      if (this.activeItem && !this.activeItem.isCompleted) {
        this.activeItem.isCompleted = false;
      }
    },
    moveToNextItem() {
      const currentSection = this.inputSections[this.currentIndex - 1];
      const nextIncompleteItem = currentSection.inputs.find(item => !item.isCompleted);

      if (nextIncompleteItem) {
        if (nextIncompleteItem.inputFormType === 'timeInput' || nextIncompleteItem.inputFormType === 'multiChoiceGrid') {
          this.openBottomSheet(nextIncompleteItem);
        }
        if (nextIncompleteItem.inputFormType !== 'surveyScore') {
          this.activateItem(nextIncompleteItem);
        }
      } else {
        this.$emit('go-next');
      }
    },
    submitAnswer(item) {
      if (item.value !== undefined && item.value !== '') {
        this.updateAnswer(item, item.value);
        console.log('Item submitted:', item);

        if (!this.allCompleted) {
          if(item.inputFormType === 'multiChoiceGrid' || item.inputFormType === 'timeInput') {
            setTimeout(() => {
              this.moveToNextItem();
            }, 700);
          } else{
            setTimeout(() => {
              this.moveToNextItem();
            }, 700);
          }
        }
      } else if (item.tempValue && item.tempValue !== '') {
        this.updateAnswer(item, item.tempValue);
      }
    },
    updateAnswer(item, value) {
      const updatedItem = this.inputSections[this.currentIndex - 1].inputs.find(
          input => input.key === item.key &&
              input.inputFormType === item.inputFormType &&
              input.inputFormSubtype === item.inputFormSubtype
      );

      if (updatedItem) {
        updatedItem.value = value;
        if (!updatedItem.isCompleted) {
          updatedItem.isCompleted = true;
        }
        updatedItem.isActive = false;
      }

      const existingAnswerIndex = this.userAnswers.findIndex(
          answer => answer.key === item.key &&
              answer.inputFormType === item.inputFormType &&
              answer.inputFormSubtype === item.inputFormSubtype
      );

      if (existingAnswerIndex !== -1) {
        this.userAnswers[existingAnswerIndex] = {
          key: item.key,
          value: value,
          inputFormType: item.inputFormType,
          inputFormSubtype: item.inputFormSubtype
        };
      } else {
        this.userAnswers.push({
          key: item.key,
          value: value,
          inputFormType: item.inputFormType,
          inputFormSubtype: item.inputFormSubtype
        });
      }

      this.$emit('answer-updated', this.userAnswersString);
    },
    openBottomSheet(item) {
      this.activateItem(item);
      this.$refs.bottomSheet.open(item);
    },
    handleBottomSheetSelect(selectedData) {
      const item = this.inputSections[this.currentIndex - 1].inputs.find(
          input => input.key === selectedData.key &&
              input.inputFormType === selectedData.inputFormType &&
              input.inputFormSubtype === selectedData.inputFormSubtype
      );
      if (item) {
        this.submitAnswer({
          ...item,
          value: selectedData.value,
          inputFormType: selectedData.inputFormType,
          inputFormSubtype: selectedData.inputFormSubtype
        });
      }
    },
    handleWarn(message) {
      this.$emit('warn', message);
    },
    handleKeyup(event) {
      console.log('Keyup event:', event);
    },
    handleAllCompleted() {
      if (this.currentIndex < this.totalIndex && this.currentIndex > 0) {
        let sectionType = this.inputSections[this.currentIndex - 1].sectionType;
        let convertedSectionType;
        if (sectionType === 'personalInfo') {
          convertedSectionType = 'myinfo';
        } else if (sectionType === 'partnerInfo') {
          convertedSectionType = 'otherinfo';
        } else if (sectionType === 'survey') {
          convertedSectionType = 'survey';
        } else {
          convertedSectionType = 'additional';
        }
        logEvent(`boxinput_${convertedSectionType}_done`, {
          'tarot_box_id': this.tarotBoxData.id,
          'tarot_box_name': this.tarotBoxData.title,
          'section_type': sectionType,
        });
      }
    },
    goPrevPage() {
      if(this.currentIndex === 1){
        this.$emit('exit');
        return;
      }
      this.cachedCurrentIndex = this.currentIndex - 1;
      let cachedSectionType = this.cachedInputSections[this.cachedCurrentIndex - 1].sectionType
      if(cachedSectionType === 'personalInfo' || cachedSectionType === 'partnerInfo'){
        this.isCheckCompOpened = true;
        this.isPageChanging = true;
      }
      this.$emit('prev');
    },
    goNextPage() {
      this.dontFocus = false;
      this.$emit('proceed');
      setTimeout(() => {
        this.isPageChanging = false;
      }, 100);
    },
    closeCheckComponent() {
      this.isCheckCompOpened = false;
    },
    closeCheckComponentClickedLater() {
      let sectionType = this.cachedInputSections[this.currentIndex -1].sectionType
      let convertedSectionType;
      if (sectionType === 'personalInfo'){
        convertedSectionType = 'myinfo';
      } else if (sectionType === 'partnerInfo'){
        convertedSectionType = 'otherinfo';
      } else if (sectionType === 'survey'){
        convertedSectionType = 'survey';
      } else {
        convertedSectionType = 'additional';
      }
      logEvent(`boxinput_${convertedSectionType}_back_click`, {
        'tarot_box_id': this.tarotBoxData.id,
        'tarot_box_name': this.tarotBoxData.title,
      });
      this.skipTransition = true;
      this.isCheckCompOpened = false;
      this.isPageChanging = false;
      // skipTransition을 다시 false로 설정하는 타이머 추가
      setTimeout(() => {
        this.skipTransition = false;
      }, 50);
    },
    async openAnswerCheckComponent() {
      if (!this.allCompleted) {
        this.$emit('warn', '모든 항목을 완료해주세요.');
        return;
      }

      const currentSection = this.inputSections[this.currentIndex - 1];
      if (currentSection && currentSection.sectionType !== 'partnerInfo' && currentSection.sectionType !== 'personalInfo') {
        this.updateUserAnswers();
        this.$emit('proceed');
        return;
      }

      this.cachedInputSections = this.inputSections;
      this.cachedCurrentIndex = this.currentIndex;
      this.isCheckCompOpened = true;
      this.isPageChanging = true;
      await this.processSajuData();
      this.updateUserAnswers();
    },
    async processSajuData() {
      const currentSection = this.inputSections[this.currentIndex - 1];
      const convertedData = this.convertSajuData();

      if (currentSection.sectionType === 'personalInfo') {
        await this.fetchSajuCharacters(convertedData.saju, 'sajuData');
      } else if (currentSection.sectionType === 'partnerInfo') {
        await this.fetchSajuCharacters(convertedData.sajuPartner, 'partnerSajuData');
      }
    },
    convertSajuData() {
      const sajuAnswers = this.userAnswers.filter(answer => answer.key === 'saju');
      const partnerSajuAnswers = this.userAnswers.filter(answer => answer.key === 'saju_partner');

      let saju = null, sajuPartner = null;

      try {
        if (sajuAnswers.length > 0) {
          saju = {
            data: convertSajuData(sajuAnswers),
            dateAndTime: convertToSajuDateAndTime(sajuAnswers)
          };
        }
        if (partnerSajuAnswers.length > 0) {
          sajuPartner = {
            data: convertSajuData(partnerSajuAnswers),
            dateAndTime: convertToSajuDateAndTime(partnerSajuAnswers)
          };
        }
      } catch (error) {
        console.error('Error converting saju data:', error);
        this.$emit('warn', '사주 데이터 변환 중 오류가 발생했습니다.');
      }

      return { saju, sajuPartner };
    },
    async fetchSajuCharacters(sajuData, storageKey) {
      if (!sajuData || !sajuData.dateAndTime) return;

      try {
        const res = await getSajuCharacters(sajuData.dateAndTime.date, sajuData.dateAndTime.sajutime);
        this[storageKey] = res.data;
      } catch (error) {
        console.error(`Failed to get ${storageKey} characters:`, error);
        this[storageKey] = [];
      }
    },
    updateUserAnswers() {
      // 1. 기존 답변들 처리
      const nonSurveyAnswers = this.userAnswers
          .filter(answer =>
              !['saju', 'saju_partner'].includes(answer.key) &&
              answer.inputFormType !== 'surveyScore'
          )
          .map(({ key, value }) => ({ key, value }));

      const surveyScores = this.userAnswers.filter(answer => answer.inputFormType === 'surveyScore');
      const consolidatedScores = {};

      surveyScores.forEach(answer => {
        if (!consolidatedScores[answer.key]) {
          consolidatedScores[answer.key] = 0;
        }
        consolidatedScores[answer.key] += parseInt(answer.value);
      });

      const consolidatedSurveyAnswers = Object.entries(consolidatedScores).map(([key, value]) => ({
        key,
        value: value.toString()
      }));

      // 2. 사주 데이터 처리
      const convertedData = this.convertSajuData();
      const sajuAnswers = [];

      if (convertedData.saju) {
        sajuAnswers.push({
          key: 'saju',
          value: convertedData.saju.data
        });
      }
      if (convertedData.sajuPartner) {
        sajuAnswers.push({
          key: 'saju_partner',
          value: convertedData.sajuPartner.data
        });
      }

      // 3. 모든 답변 합치기
      let updatedUserAnswers = [
        ...nonSurveyAnswers,
        ...consolidatedSurveyAnswers,
        ...sajuAnswers
      ];

      if (this.presetNameForHeader && this.presetNameForHeader.personalInfo) {
        console.log('Preset name for header:', this.presetNameForHeader.personalInfo);
        // 기존에 name, my_name 항목이 있다면 제거
        updatedUserAnswers = updatedUserAnswers.filter(answer =>
            answer.key !== 'name' && answer.key !== 'my_name'
        );
        updatedUserAnswers.push({
          key: 'name',
          value: this.presetNameForHeader.personalInfo
        });
        updatedUserAnswers.push({
          key: 'my_name',
          value: this.presetNameForHeader.personalInfo
        });
      }

      // 5. 최종 JSON 문자열 생성 및 emit
      this.stringUserAnswers = JSON.stringify(updatedUserAnswers);
      console.log('Updated User Answers:', updatedUserAnswers);
      this.$emit('prepare', this.stringUserAnswers);
    },
    openUserSajuSelectPopup() {
      this.$refs.bottomSheet.open({
        inputFormType: 'userBirthInfoSelection',
        key: 'saju',
        value: '',
        inputFormHeaderLabel: '사주를 선택해주세요',
        inputFormQuestion: '사주를 선택해주세요',
        options: [
          { value: '양력' },
          { value: '음력' }
        ]
      });
    },
    applyPresetData(preset) {
      if (!preset) return;

      const currentSection = this.inputSections[this.currentIndex - 1];
      if (!currentSection) return;

      // key에 'saju'가 포함된 input이 하나라도 있는지 확인
      const hasSajuInput = currentSection.inputs.some(input =>
          input.key.toLowerCase().includes('saju')
      );

      // saju 관련 input이 없으면 함수 종료
      if (!hasSajuInput) return;
      this.isSajuKeyIncluded = true;

      const m = moment(preset.birth_datetime);
      const presetDate    = m.format("YYYY-MM-DD");
      const presetTimeRaw = m.format("HH:mm");
      const presetTime    = `${presetTimeRaw.split(':')[0]}시 ${presetTimeRaw.split(':')[1]}분`;

      const presetName    = preset.name;
      const presetGender  = preset.gender;

      if (currentSection.sectionType === 'personalInfo') {
        this.dontFocus = true;
        currentSection.inputs.forEach(input => {
          if (input.inputFormType === 'dateInput' && input.key === 'saju') {
            input.value = presetDate;
            input.isCompleted = true;
          }
          else if (input.inputFormType === 'timeInput' && input.key === 'saju') {
            input.value = presetTime;
            input.isCompleted = true;
          }
          else if (input.inputFormType === 'genderInput' && input.key === 'saju') {
            input.value = presetGender;
            input.isCompleted = true;
          }
        });
        this.presetNameForHeader.personalInfo = presetName;
      }
      else if (currentSection.sectionType === 'partnerInfo') {
        this.dontFocus = true;
        currentSection.inputs.forEach(input => {
          if (input.inputFormType === 'textInput' && input.key === 'partner_name') {
            input.value = presetName;
            input.tempValue = presetName;
            input.isCompleted = true;
          }
          else if (input.inputFormType === 'dateInput' && input.key === 'saju_partner') {
            input.value = presetDate;
            input.isCompleted = true;
          }
          else if (input.inputFormType === 'timeInput' && input.key === 'saju_partner') {
            input.value = presetTime;
            input.isCompleted = true;
          }
          else if (input.inputFormType === 'genderInput' && input.key === 'saju_partner') {
            input.value = presetGender;
            input.isCompleted = true;
          }
        });
        this.presetNameForHeader.partnerInfo = presetName;
      }

      currentSection.inputs.forEach(input => {
        if (input.isCompleted) {
          this.updateAnswer(input, input.value);
        }
      });
      this.updateUserAnswers();
    },

    async handleUserSajuSelect(data) {
      console.log('Preset selected via handleUserSajuSelect:', data);
      this.applyPresetData(data);
    },
    openSajuCreatePage() {
      this.isSajuCreatePageOpened = true;
      this.$refs.bottomSheet.close();
    },
    openSajuEditPage(id) {
      this.selectedSaju = this.userBirthInfos.find(info => info.id === id);
      if (this.selectedSaju) {
        this.isSajuEditPageOpened = true;
        this.$refs.bottomSheet.close();
      }
    },
    closeSajuPage() {
      this.$emit('update-saju-infos')
      this.isSajuCreatePageOpened = false;
      this.isSajuEditPageOpened = false;
      this.openUserSajuSelectPopup();
    }
  }
}
</script>

<style scoped>
.detail-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  background-color: var(--Grayscale-Gray12);
  padding-top: var(--safe-area-top);
  box-sizing: border-box;
  overscroll-behavior: contain;
  overscroll-behavior-y: none;
}

.content-container {
  flex-grow: 1;
  overflow-y: auto;
  padding-bottom: 54px;
  overscroll-behavior: contain;
  overscroll-behavior-y: none;
}

.proceed-button-wrapper {
  position: absolute;  /* absolute 대신 fixed 사용 */
  bottom: -100px;  /* 초기 위치를 화면 밖으로 설정 */
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 24px calc(10px + var(--safe-area-bottom)) 24px;
  box-sizing: border-box;
  background-color: white;
  gap: 8px;
  z-index: 30;
  transition: bottom 0.5s ease;  /* 트랜지션 추가 */
}

.proceed-button-wrapper.show-button {
  bottom: 0;  /* 최종 위치 */
}

.proceed-button {
  display: flex;
  flex: 5;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 13px;
  background: var(--Color-palette-Primary-Default);
  color: white;
  font-size: 16px;
  font-weight: 700;
}

.proceed-button-disabled {
  display: flex;
  flex: 5;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 13px;
  color: white;
  font-size: 16px;
  font-weight: 700;
  pointer-events: none;
  background: var(--Color-palette-Primary-Lighten3, #B9C0D5);
}

.proceed-button-white {
  display: flex;
  flex: 2;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  padding: 13px;
  background: var(--Grayscale-Gray12);
  color: var(--Color-palette-Primary-Default);
  outline: 2px solid var(--Color-palette-Primary-Default);
  font-size: 16px;
  font-weight: 700;
}

.detail-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  background-color: white;
  padding: 54px 24px;
  box-sizing: border-box;
  overscroll-behavior: contain;
  overscroll-behavior-y: none;
}

.sections {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  position: relative;
}

.section-items {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 20px;
  width: 100%;
  gap: 28px;
}

.item {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
}

.title3 {
  color: var(--Grayscale-Gray8);
}

.select-item {
  width: 100%;
  padding: 13px 16px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: 2px solid var(--Grayscale-Gray10, #ECECEC);
}

.select-item.active {
  border: 2px solid var(--Grayscale-Gray2, #242424);
}

.select-item.completed .title3 {
  color: var(--Grayscale-Gray3);
}

.input-form {
  margin-top: 10px;
  display: flex;
  width: 100%;
}

.input-form input {
  flex-grow: 1;
  padding: 8px;
  border: 1px solid var(--Grayscale-Gray10);
  border-radius: 4px;
}


.input-form button {
  margin-left: 10px;
  padding: 8px 16px;
  background-color: var(--Color-palette-Primary-Default);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.confirmation-button {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  box-sizing: border-box;
  background-color: white;
}

.button {
  background: var(--Color-palette-Primary-Default);
  width: 100%;
  height: 52px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.button.disabled {
  background: var(--Color-palette-Primary-Lighten3, #B9C0D5);
  cursor: not-allowed;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-active.first-item {
  transition-delay: 0.5s;
}

.list-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}

.list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.list-move {
  transition: transform 0.5s ease;
}

/* 첫 번째 아이템이 아닌 경우에 대한 딜레이 */
.list-enter-active:not(.first-item) {
  transition-delay: 0.2s;
}

.slide-left-leave-active {
  transition: all 0.5s ease-out;
}

.slide-left-leave-to {
  transform: translateX(-100%);
}

.slide-left-enter-from,
.slide-left-enter-active,
.slide-left-enter-to {
  transition: none;
}


</style>
