<template>
  <div class="modal" v-if="isActive">
    <div class="overlay" @click="closePopup"></div>
    <div class="delete-popup-container" ref="popupContainer">
      <div class="popup-header">
        <span class="title2" style="margin-bottom: 12px;">
          사주정보를 삭제하시겠어요?
        </span>
        <span class="text2" style="margin-bottom: 20px;">
          삭제 후 복구할 수 없으니 다시 한 번 확인해주세요
        </span>
      </div>
      <div class="popup-content">
      </div>
      <div class="popup-footer">
        <div class="btn2" @click="cancelDelete" >
          취소
        </div>
        <div class="btn2" @click="confirmDelete" style="flex: 1; background: var(--Color-palette-Primary-Default); color: var(--Grayscale-Gray12)">
          삭제
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {logEvent} from "@/tarot_box/helper/native_api";

export default {
  name: "MySajuDeleteConfirmPopup",
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  emits: ['delete', 'close'],
  data() {
    return {
    }
  },
  mounted() {
    window.onBackButtonClick = this.handleBackButton;
  },
  beforeUnmount() {
    window.onBackButtonClick = null;
  },
  methods: {
    cancelDelete() {
      logEvent('saju_edit_delete_popup_cancel',{})
      this.$emit('close');
    },
    confirmDelete() {
      this.$emit('delete');
    },
  }
}
</script>

<style scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.overlay {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
}

.delete-popup-container {
  position: fixed;
  top: 50%; /* 화면의 수직 중앙에 위치하도록 설정 */
  left: 50%; /* 화면의 수평 중앙에 위치하도록 설정 */
  transform: translate(-50%, -50%); /* 팝업의 중심을 화면 중심에 정확히 맞추기 위해 */
  width: calc( 100vw - 40px);
  padding: 30px 0 20px 0;
  background-color: #FFFFFF;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  border-radius: 8px;
}

.popup-header{
  display: flex;
  flex-direction: column;
}

.popup-footer{
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin: 16px 16px 0 16px;
  justify-content: space-between;
}

.popup-content{
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.btn2{
  flex: 1;
  display: flex;
  padding: 12px 0;
  align-items: center;
  justify-content: center;
  background-color: var(--Grayscale-Gray10);
  color: var(--Grayscale-Gray3);
  font-size: 16px;
  font-weight: 700;
  border-radius: 6px;
}


</style>