<template>
  <teleport to="body">
    <ExtraQuestionTicketPaywall
        ref="ticketPaywall"
        v-if="isExtraQuestionTicketPaywallActive"
        :is-teleported="true"
        :ticket-data="paywallData?.product_list"
        :tarot-user-data="paywallData?.tarot_user"
        @alert-toast="showInfoToast($event)"
        @go-clover-paywall="openCloverPaywall"
        @close-ticket="closeExtraQuestionTicketPaywall"
    />
  </teleport>
  <teleport to="body">
    <RatingsPopup
        v-if="isRatingsPopupOpen"
        :title="currentRatingConfig.title"
        :desc="currentRatingConfig.desc"
        :from="currentRatingConfig.from"
        @submit-rating="handleRatingSubmit"/>
  </teleport>
  <teleport to="body">
    <CloverPaywall
        v-if="isCloverPaywallActive"
        :is-teleported="true"
        @close-clover="closeCloverPaywall"/>
  </teleport>
  <NotFoundPage v-if="!tarotBoxHistory"/>
  <ServiceErrorPopup v-if="isServiceErrorPopupActive"
                     @close="closeErrorPopup"/>
  <Loading v-model:active="isLoading"
           loader="dots"
           color="#1E2352"
           :opacity="1"
           :z-index="99">
    <template v-slot:default>
      <div class="custom-loader">
        <img :src="require('@/tarot_box/pages/box_result_v2/assets/main-loading/logo.svg')" alt="loader" class="loader-img"/>
        <img :src="require('@/tarot_box/pages/box_result_v2/assets/main-loading/moving.svg')" alt="moving" class="moving-svg"/>
      </div>
    </template>
  </Loading>
  <div class="output-v2-page" :style="{ backgroundColor: backgroundColor }">
    <OutputTopBar v-if="currentIndex !== 0 && !isShare"
                  :is-share="isShare"
                  @open-share-menu="openShareMenu"
                  @close-button-click="exitResultPage"/>
    <OutputBottomBar v-if="currentIndex !== 0 && showBars"
                     :current-index="currentIndex"
                     :total-index="totalIndex"
                     :tarot-box-history="tarotBoxHistory"
                     :is-bottom-sheet-open="isBottomSheetOpen"
                     @openBottomSheet="openBottomSheet"
                     @closeBottomSheet="closeBottomSheet"
                     @go-next="goNextPage"
                     @go-prev="goPrevPage"/>
    <keep-alive>
    <CoverImage
      v-if="currentIndex === 0 && tarotBoxHistory"
      @click-cover="goPageOne"
      @go-back="exitFromCoverPage"
      :is-share="isShare"
      :tarot-box-history="tarotBoxHistory"/>
    </keep-alive>
    <OutputList
      v-if="currentIndex === 1"
      :is-share="isShare"
      :tarot-box-history="tarotBoxHistory"
      :background-color="backgroundColor"
      @go-page="goPage"/>
    <keep-alive>
    <OutputDetails
      ref="outputDetails"
      v-if="currentIndex >= 0"
      :current-index="currentIndex"
      :is-share="isShare"
      :tarot-box-history="tarotBoxHistory"
      :selected-card-data="selectedCardData"
      :user-name="userName"
      :user-data="userData"
      :is-animation-done="isAnimationDone"
      :question="question"
      :last-asked-question="lastAskedQuestion"
      :extra-question-status="extraQuestionStatus"
      :request-successful="requestSuccessful"
      :total-extra-answer-count="totalExtraAnswerCount"
      :current-answer-index="currentAnswerIndex"
      :is-popup-shown="isDialogPopupShown"
      :is-interpreting="isInterpreting"
      @next-pages="goNextPage"
      @request="requestExtraQuestion"
      @request-more="requestAnotherExtraQuestion"
      @update:question="updateQuestion"
      @user-update="checkAndGetUserInfo"
      @animation-done="isAnimationDone = true"
      @open-card-info="openCardInfo"
      @open-sipsung-info="openSipsungInfo"
      @tarot-spread-open="onTarotSpreadOpen"
      @saju-sipsung-open="onSajuSipsungOpen"
      @hide-bottom="showBars = false"
      @show-bottom="showBars = true"
      @open-ratings-popup="openRatingsPopupFromExtarAnswer"
      class="no-bounce"
      :background-color="backgroundColor"/>
    </keep-alive>
    <BottomSheet
      ref="bottomSheet"
      :type="bottomSheetType"
      :current-index="currentIndex"
      :tarot-box-history="tarotBoxHistory"
      @link-share="linkShare"
      @kakao-share="kakaoShare"
      @go-page="goPage"
      @opened="onBottomSheetOpen"
      @closed="onBottomSheetClose" />
    <ReviewPromptPopup
      :history-id="tarotBoxHistory.id"
      :hash-id="this.$route.query.hash_id"
      :from-v2="true"
      :feedback-reward="tarotBoxHistory.feedback_reward"
      @exit="goMytarotPage"
      v-if="isReviewPopupOpen"/>
  </div>

  <div class="output-toast"
       v-show="showToast"
       :class="{ 'show': showToast }"
       :style="toastStyle">
    {{ toastMessage }}
  </div>

</template>

<script>
import CoverImage from "@/tarot_box/pages/box_result_v2/components/CoverImage.vue";
import OutputList from "@/tarot_box/pages/box_result_v2/components/OutputList.vue";
import OutputTopBar from "@/tarot_box/pages/box_result_v2/components/OutputTopBar.vue";
import OutputBottomBar from "@/tarot_box/pages/box_result_v2/components/OutputBottomBar.vue";
import OutputDetails from "@/tarot_box/pages/box_result_v2/components/OutputDetails/OutputDetails.vue";
import ReviewPromptPopup from "@/tarot_box/pages/box_result/components/ReviewPromptPopup.vue";
import BottomSheet from "@/tarot_box/pages/box_result_v2/components/BottomSheets/BottomSheet.vue";
import NotFoundPage from "@/tarot_box/components/NotFoundPage.vue"


import {mapActions} from "vuex";
import Loading from 'vue-loading-overlay';
import _ from 'lodash';
import {
  askExtraQuestion, getExtarQuestionPaywall, getMyDailyFortune,
  getMyTarotResultByFetch,
  getTarotUserInfo,
  requestPaidExtraQuestion
} from "@/tarot_box/helper/mytarot_api";
import {
  changeBottomSafeAreaColor,
  changeTopSafeAreaColor, hideBottomBar,
  logEvent,
  showBottomBar
} from "@/tarot_box/helper/native_api";
import {openBlock} from "vue";
import ServiceErrorPopup from "@/ServiceErrorPopup.vue";
import {goToMyTarotTab} from "@/common/app_webview_handler";
import ExtraQuestionTicketPaywall from "@/paywall/ExtraQuestionTicketPaywall.vue";
import CloverPaywall from "@/paywall/CloverPaywall.vue";
import RatingsPopup from "@/tarot_box/components/RatingsPopup.vue";

export default {
  name: "OutputPage",
  components: {
    RatingsPopup,
    CloverPaywall,
    ExtraQuestionTicketPaywall,
    ServiceErrorPopup,
    NotFoundPage,
    ReviewPromptPopup,
    BottomSheet,
    CoverImage,
    OutputList,
    OutputTopBar,
    OutputBottomBar,
    OutputDetails,
    Loading
  },
  data() {
    return {
      isServiceErrorPopupActive: false,
      buildNumber: 0,
      isShare: false,
      language: this.$route.params.language,
      backgroundColor: '#f5f5f5',
      fontsLoaded: false,
      currentIndex: 0,
      totalIndex: 1,
      bottomSheetType: 'index',
      colorMode: 'light',
      isFirstOpen: true,
      isTarotGuideShown: false,
      isSajuGuideShown: false,
      isAnimationDone: false,
      question: '',
      lastAskedQuestion: '',
      isRequested: false,
      requestSuccessful: false,
      isLoading: true,
      isReviewPopupOpen: false,
      isBottomSheetOpen: false,
      safeAreaColor: '#FFFFFF',
      showBars: true,
      firstInit: true,
      totalExtraAnswerCount: 0,
      currentAnswerIndex: -1,
      resultData: null,
      tarotBoxHistory: null,
      tarotBoxInfo: null,
      selectedCardData: null,
      extraQuestionStatus: null,
      userName: '',
      userData: null,
      paywallData: null,
      refreshInterval: null,
      isExtraQuestionTicketPaywallActive: false,
      isCloverPaywallActive: false,
      insetTop: 0,
      isDialogPopupShown: false,
      isRatingsPopupOpen: false,
      userAccDailyfortuneCount: 0,
      ratingPopupConfig: {
        dailyFortune: {
          title: "오늘의 운세 결과가 마음에 드셨나요?",
          desc: "남겨주신 별점은 마이타로 컨텐츠 제작에 큰 도움이 돼요",
          from: "daily-fortune",
          onSubmit: 'handleDailyFortuneRatingSubmit'
        },
        review: {
          title: "심층풀이 결과가 마음에 드셨나요?",
          desc: "남겨주신 별점은 마이타로 해석풀이 개선에 큰 도움이 돼요",
          from: "extra-question",
          onSubmit: 'handleExtraQuestionRatingSubmit'
        }
      },
      showToast: false,
      toastMessage: ''
    }
  },
  async beforeMount() {
    await this.initTarotBoxResult();
    this.loadFonts();
  },
  inject: ['appListener', 'appCommander'],
  mounted() {
    hideBottomBar();
    this.refreshInterval = setInterval(async () => {
      if (this.requestSuccessful === true ||
          (this.tarotBoxHistory &&
              (this.tarotBoxHistory.extra_question_status === 'processing' ||
                  this.tarotBoxHistory.extra_question_status === 'paid_processing'))) {
        await this.initTarotBoxResult();
      }
    }, 1500);
    document.documentElement.classList.remove('dark-mode');
    if (this.language === ""){
      this.language = "ko"
    }
    this.buildNumber = parseInt(this.$route.query.build_number);
    if(this.$route.query.is_share === 'true'){
      this.isShare = true;
    }
    const urlParams = new URLSearchParams(window.location.search);
    this.insetTop = parseInt(urlParams.get('inset_top') || '0', 10);

    this.$store.state.feedbackBoxVisible = true;
    window.onBackButtonClick = this.handleBackButton;
    this.checkAndGetUserInfo();
    this.checkAndTicketPaywallInfo();
  },
  unmounted() {
    showBottomBar();
    clearInterval(this.refreshInterval);
  },
  watch: {
    colorMode(newMode) {
      if (newMode === 'dark') {
        document.documentElement.classList.add('dark-mode');
      } else {
        document.documentElement.classList.remove('dark-mode');
      }
    },
    currentIndex(newIndex) {
      if(newIndex < 1){
        this.addSafeAreaColorTop(this.safeAreaColor);
        this.addSafeAreaColorBottom(this.safeAreaColor);
      }else{
        this.addSafeAreaColorTop('#FFFFFFFF');
        this.addSafeAreaColorBottom('#FFFFFFFF');
      }
    }
  },
  beforeUnmount() {
    document.documentElement.classList.remove('dark-mode');
  },
  computed: {
    isWeb() {
      return this.$isWeb();
    },
    toastStyle() {
      return {
        top: `${55 + this.insetTop}px`,
        left: '50%',
        transform: this.showToast ? 'translate(-50%, 0)' : 'translate(-50%, -100%)'
      }
    },
    isInterpreting() {
      if(this.tarotBoxHistory){
        return this.tarotBoxHistory.extra_question_status === 'processing' || this.tarotBoxHistory.extra_question_status === 'paid_processing';
      } else{
        return false;
      }
    },
    currentRatingConfig() {
      return this.shouldShowDailyFortuneRating()
          ? this.ratingPopupConfig.dailyFortune
          : this.ratingPopupConfig.review;
    }
  },
  methods: {
    openBlock,
    ...mapActions(['addSafeAreaColorTop', 'addSafeAreaColorBottom', 'previousSafeAreaColorTop', 'previousSafeAreaColorBottom']),
    goPageOne(){
      this.currentIndex = 1;
      logEvent("result_cover_click", {});
    },
    async checkAndGetUserInfo() {
      const urlParams = new URLSearchParams(decodeURIComponent(window.location.search));
      const tarotUserId = parseInt(urlParams.get('tarot_user_id'));
      const phoneNumber = urlParams.get('phone_number');
      const buildNumber = parseInt(urlParams.get('build_number'));
      if(!this.isWeb && tarotUserId && phoneNumber){
        let res = await getTarotUserInfo(tarotUserId, phoneNumber, buildNumber);
        if(res.result === 'success'){
          this.userData = res.data;
        }
        const dailyRes = await getMyDailyFortune(phoneNumber, tarotUserId, buildNumber)
        if(dailyRes.result === 'success'){
          this.userAccDailyfortuneCount = dailyRes.data.acc_count;
        }
      }
    },
    async checkAndTicketPaywallInfo(){
      const urlParams = new URLSearchParams(decodeURIComponent(window.location.search));
      const tarotUserId = parseInt(urlParams.get('tarot_user_id'));
      const phoneNumber = urlParams.get('phone_number');
      if(!this.isWeb && tarotUserId && phoneNumber){
        let res = await getExtarQuestionPaywall(tarotUserId, phoneNumber);
        if(res.result === 'success'){
          this.paywallData = res.data;
        }
      }
    },
    loadFonts() {
      const fontFaces = document.fonts.values();
      Promise.all([...fontFaces].map(font => font.load()))
          .then(() => {
            this.fontsLoaded = true;
          })
          .catch((error) => {
            console.error('Error loading fonts:', error);
          });
    },
    async initTarotBoxResult() {
      try {
        const urlParams = new URLSearchParams(decodeURIComponent(window.location.search));
        let hashId = urlParams.get("hash_id");
        if (!hashId) {
          throw new Error("don't have hash_id in url");
        }

        const newResultData = await this.fetchTarotData(hashId);

        // 이전 데이터와 새로운 데이터 비교
        const isDataChanged = this.compareResultData(this.resultData, newResultData);

        if (isDataChanged) {
          this.setTarotData(newResultData);
          this.handleExternalShare();
          this.handleTags();
          this.currentAnswerIndex = this.getExtraAnswerIndex(this.currentIndex - 1);
          const extraStatus = newResultData.tarot_box_history.extra_question_status;
          //새로 결과가 내려왔을 때 가장 마지막 extra answer로 이동하는 로직, answer data 초기화
          if(this.firstInit === false){
            if(extraStatus === 'paid_new' || extraStatus === 'new'){
              this.currentIndex = this.findLastExtraAnswerIndex();
              this.currentAnswerIndex = this.getExtraAnswerIndex(this.currentIndex - 1);
              this.question = '';
              this.checkAndGetUserInfo();
            }
          }
          if(extraStatus === 'finished' || extraStatus === 'paid_finished' || extraStatus === 'new' || extraStatus === 'paid_new'){
            this.requestSuccessful = false;
            this.isRequested = false;
          }
          await this.loadImages();
          setTimeout(() => {
            this.finishLoading();
          }, 1000);
          this.firstInit = false;
        }
      } catch (error) {
        console.error(error);
        this.showErrorToast('결과를 불러오는데 실패했습니다\n잠시 후 다시 시도해주세요');
        this.isLoading = false;
      }
    },
    compareResultData(oldData, newData) {
      if (!oldData) {
        return true;
      }
      if (oldData.tarot_box_history.extra_question_status !== newData.tarot_box_history.extra_question_status) {
        if(newData.tarot_box_history.extra_question_status === 'new' || newData.tarot_box_history.extra_question_status === 'paid_new') {
          this.$refs.outputDetails.scrollToTop();
          this.requestSuccessful = false;
        }
        return true;
      }
      return false; // 위의 조건들을 모두 통과하면 변경 사항 없음
    },
    finishLoading() {
      this.isLoading = false;
      if(this.currentIndex === 0){
        this.addSafeAreaColorTop(this.safeAreaColor);
        this.addSafeAreaColorBottom(this.safeAreaColor);
        if(this.firstInit === true){
          logEvent("result_into", {
            "tarot_box_id": this.tarotBoxHistory.tarot_box_id,
            "tarot_box_name": this.tarotBoxHistory.tarot_box_title,
            "tarot_box_history_id": this.tarotBoxHistory.id,
          });
        }
      }
    },
    async fetchTarotData(hashId) {
      let resultData = await getMyTarotResultByFetch(hashId);
      if (resultData === null || resultData === undefined) {
        throw new Error("결과를 불러오는데 실패했습니다");
      }
      return resultData;
    },
    setTarotData(resultData) {
      this.resultData = resultData;
      this.tarotBoxHistory = resultData.tarot_box_history;
      this.tarotBoxInfo = resultData.tarot_box;
      this.selectedCardData = resultData.selected_card_data;
      this.totalIndex = this.tarotBoxHistory.result_page_data.length;
      this.userName = resultData.user_name;
      this.extraQuestionStatus = resultData.tarot_box_history.extra_question_status;
      this.lastAskedQuestion = resultData.tarot_box_history.extra_question;
      this.backgroundColor = resultData.tarot_box_history.output_page_meta_data.background_color;
      this.colorMode = resultData.tarot_box_history.output_page_meta_data.color_mode;
      this.safeAreaColor = resultData.tarot_box_history.output_page_meta_data.cover_image_color;
      this.totalExtraAnswerCount = resultData.tarot_box_history.result_page_data.filter(item => item.page_type === 'extra_answer').length;
    },
    handleExternalShare() {
      if (this.$route.query.external === 'true') {
        const extraAnswerIndex = this.tarotBoxHistory.result_page_data.findIndex(
            (item) => item.page_type === 'extra_question'
        );
        if (extraAnswerIndex !== -1) {
          const titlePageIndex = extraAnswerIndex - 1;
          this.tarotBoxHistory.result_page_data.splice(titlePageIndex, 2);
          for (let i = titlePageIndex; i < this.tarotBoxHistory.result_page_data.length; i++) {
            this.tarotBoxHistory.result_page_data[i].page_index -= 2;
          }
        }
      }
    },
    handleTags() {
      const tags = this.$route.query.tags;
      this.isFirstOpen = tags !== '';

      if (!tags) return;

      this.addSafeAreaColorTop('#FFFFFFFF');
      this.addSafeAreaColorBottom('#FFFFFFFF');

      let targetIndex;

      switch (tags) {
        case 'extra':
          targetIndex = this.findPageIndex('부록', 'title_page');
          if (targetIndex !== -1) this.currentIndex = targetIndex + 1;
          break;
        case 'extra_question':
          targetIndex = this.findPageIndex('부록', 'title_page');
          if (targetIndex !== -1) this.currentIndex = targetIndex + 2;
          break;
        case 'more':
          targetIndex = this.findLastExtraAnswerIndex();
          if (targetIndex !== -1) this.currentIndex = targetIndex;
          break;
      }
      this.$router.replace({ query: { ...this.$route.query, tags: '' } });
    },
    findPageIndex(title, type) {
      return this.tarotBoxHistory.result_page_data.findIndex(
          item => item.page_title === title && item.page_type === type
      );
    },
    findLastExtraAnswerIndex() {
      const extraAnswers = this.tarotBoxHistory.result_page_data.filter(
          item => item.page_type === 'extra_answer'
      );
      return extraAnswers.length > 0 ? extraAnswers[extraAnswers.length - 1].page_index : -1;
    },
    async loadImages() {
      const imageUrls = [
        this.tarotBoxHistory.output_page_meta_data.splash_image_url,
        this.tarotBoxHistory.output_page_meta_data.title_image_url,
        this.tarotBoxHistory.output_page_meta_data.cta_image_url,
        this.tarotBoxHistory.output_page_meta_data.background_image_url,
        this.tarotBoxHistory.output_page_meta_data.objet_icon_image_url
      ].filter(url => url);
      const tarotCardUrls = this.selectedCardData.map(card => card.card_img_url);
      const allImageUrls = [...imageUrls, ...tarotCardUrls];
      await Promise.all(allImageUrls.map(url => this.loadImage(url)));
    },
    closeErrorPopup() {
      this.isServiceErrorPopupActive = false;
    },
    loadImage(url) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve();
        img.onerror = () => reject();
        img.src = url;
      });
    },
    showErrorToast(message) {
      this.toastMessage = message;
      this.showToast = true;
      setTimeout(() => {
        this.showToast = false;
        this.toastMessage = '';
        this.goMytarotPage()
      }, 10000);
    },
    showInfoToast(message) {
      this.toastMessage = message;
      this.showToast = true;
      setTimeout(() => {
        this.showToast = false;
        this.toastMessage = '';
      }, 3000);
    },
    openBottomSheet() {
      this.bottomSheetType = 'index';
      this.$refs.bottomSheet.open();
    },
    closeBottomSheet() {
      this.$refs.bottomSheet.close();
    },
    openCardInfo(cardId) {
      this.bottomSheetType = 'tarotInfo';
      this.$refs.bottomSheet.open(cardId);
    },
    openSipsungInfo(sipsungInfo) {
      this.bottomSheetType = 'sipsungInfo';
      this.$refs.bottomSheet.open(sipsungInfo);
    },
    onTarotSpreadOpen() {
      if(!this.isFirstOpen){
        return;
      }else if(this.isTarotGuideShown){
        return;
      }else {
        this.bottomSheetType = 'tarotGuide';
        this.$refs.bottomSheet.open();
        this.isTarotGuideShown = true;
      }
    },
    onSajuSipsungOpen() {
      if(!this.isFirstOpen){
        return;
      }else if(this.isSajuGuideShown){
        return;
      }else {
        this.bottomSheetType = 'sajuGuide';
        this.$refs.bottomSheet.open();
        this.isSajuGuideShown = true;
      }
    },
    openShareMenu(){
      this.bottomSheetType = 'share';
      this.$refs.bottomSheet.open();
    },
    onBottomSheetOpen() {
      this.isBottomSheetOpen = true;
    },
    onBottomSheetClose() {
      this.isBottomSheetOpen = false;
    },
    goNextPage() {
      if (this.currentIndex === this.totalIndex) {
        return;
      } else {
        this.currentAnswerIndex = this.getExtraAnswerIndex(this.currentIndex);
        this.currentIndex += 1;
        this.$refs.outputDetails.scrollToTop();
      }
    },
    getExtraAnswerIndex(currentIndex) {
      const extraAnswerIndices = [];
      this.resultData.tarot_box_history.result_page_data.forEach((page, index) => {
        if (page.page_type === 'extra_answer') {
          extraAnswerIndices.push(index);
        }
      });
      const extraAnswerIndex = extraAnswerIndices.indexOf(currentIndex);
      return extraAnswerIndex !== -1 ? extraAnswerIndex + 1 : -1;
    },
    goPrevPage() {
      if (this.currentIndex === 0) {
        return;
      } else {
        this.currentIndex -= 1;
        this.currentAnswerIndex = this.getExtraAnswerIndex(this.currentIndex - 1);
        this.$refs.outputDetails.scrollToTop();
      }
    },
    goPage(index) {
      console.log('goPage', index)
      this.currentIndex = index;
    },
    updateQuestion(value) {
      this.question = value;
    },
    linkShare() {
      logEvent('result_UI_share_popup_link', {})
      const urlParams = new URLSearchParams(window.location.search);
      const historyId = urlParams.get('history_id');
      const hashId = urlParams.get('hash_id');
      const pathSegments = window.location.pathname.split('/');
      const dynamicId = pathSegments[pathSegments.length - 1];

      const shareUrl = `https://mytarot.co.kr/tarot_box/result_v2/${dynamicId}?historyId=${historyId}&1z_external_browser=true&hash_id=${hashId}&is_share=true&external=true`;
      if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(shareUrl)
            .then(() => {
              this.toastMessage = '링크가 복사되었습니다';
              this.showToast = true;
              console.log('Link copied to clipboard');
              setTimeout(() => {
                this.showToast = false;
                this.toastMessage = '';
              }, 3000);
            })
            .catch(err => {
              console.error('Error in copying text: ', err);
            });
      } else {
        // Fallback: 기존 방식 사용
        const textarea = document.createElement('textarea');
        textarea.value = shareUrl;
        document.body.appendChild(textarea);
        textarea.select();
        textarea.setSelectionRange(0, 9999);
        document.execCommand('copy');
        document.body.removeChild(textarea);
      }
    },
    kakaoShare() {
      logEvent('result_UI_share_popup_kakao', {})

      const urlParams = new URLSearchParams(window.location.search);
      const historyId = urlParams.get('history_id');
      const hashId = urlParams.get('hash_id');
      const pathSegments = window.location.pathname.split('/');
      const dynamicId = pathSegments[pathSegments.length - 1];

      const shareUrl = `https://mytarot.co.kr/tarot_box/result_v2/${dynamicId}?historyId=${historyId}&hash_id=${hashId}&is_share=true&external=true`;

      const kakao = window.Kakao;
      kakao.Share.sendDefault({
        objectType: 'feed',
        content: {
          title: `${this.resultData.user_name}님의 '${this.tarotBoxInfo.title}'`,
          description: this.tarotBoxInfo.sub_title,
          imageUrl: this.tarotBoxInfo.thumbnail_v2_img_url,
          link: {
            mobileWebUrl: shareUrl,
            webUrl: shareUrl,
          },
        },
        buttons: [
          {
            title: '타로 결과보기',
            link: {
              mobileWebUrl: shareUrl,
              webUrl: shareUrl,
            },
          },
        ],
      })
    },
    goMytarotPage(){
      if(this.buildNumber < 84){
        this.$router.go(-1);
      } else {
        goToMyTarotTab();
      }
    },
    showReviewPopup() {
      this.isReviewPopupOpen = true;
    },
    handleBackButton() {
      if(this.isRatingsPopupOpen){
        return null
      }
      if (this.currentIndex > 0) {
        this.currentIndex--;
        return 'back';
      } else if (this.currentIndex === 0) {
        setTimeout(() => {
          this.exitResultPage();
        }, 100);
        return 'back';
      }
    },
   requestExtraQuestion: _.throttle(async function() {
     this.requestSuccessful = true;
     logEvent('result_extra_request_click', {});
      this.isRequested = true;

      let historyId;
      if (this.tarotBoxHistory === '' || this.tarotBoxHistory === null || this.tarotBoxHistory === undefined) {
        historyId = this.$route.query.history_id;
      } else {
        historyId = this.tarotBoxHistory.id;
      }

      if (!historyId) {
        console.error('History ID is not available');
        this.isRequested = false;
        return;
      }

      try {
        let res = await askExtraQuestion(historyId, this.question);
        if (res.result === 'success') {
          this.requestSuccessful = true;
          logEvent('result_extra_success_popup', {});
        } else {
          this.isRequested = false;
        }
      } catch (error) {
        console.error('Error asking extra question:', error);
        this.isRequested = false;
        this.requestSuccessful = false;
      }
    }, 500, { 'leading': true, 'trailing': false }),

    requestAnotherExtraQuestion: _.throttle(async function() {
      this.requestSuccessful = true;
      if(this.userData?.extra_question_ticket < 1){
        this.isExtraQuestionTicketPaywallActive = true;
        this.$refs.outputDetails.$refs.extraAnswerComponent.closePopup();
        return;
      }

      let historyId;
      if (this.tarotBoxHistory === '' || this.tarotBoxHistory === null || this.tarotBoxHistory === undefined) {
        historyId = this.$route.query.history_id;
      } else {
        historyId = this.tarotBoxHistory.id;
      }

      try {
        let res = await requestPaidExtraQuestion(historyId, this.question);
        if (res.result === 'success') {
          this.requestSuccessful = true;
        }
      } catch(error) {
        this.showInfoToast('일시적인 오류가 발생했습니다.\n잠시 후 다시 시도해주세요')
        this.requestSuccessful = false;
      }
    }, 500, { 'leading': true, 'trailing': false }),
    closeExtraQuestionTicketPaywall(){
      this.checkAndGetUserInfo();
      this.isExtraQuestionTicketPaywallActive = false;
      this.requestSuccessful = false;
    },
    openCloverPaywall(){
      this.isCloverPaywallActive = true;
    },
    closeCloverPaywall(){
      this.checkAndTicketPaywallInfo();
      if (this.$refs.ticketPaywall) {
        this.$refs.ticketPaywall.getUserData();
      }
      this.isCloverPaywallActive = false;
      this.requestSuccessful = false;
    },
    async handleRatingSubmit(rating) {
      const config = await this.getCurrentRatingConfig();
      const handler = config.onSubmit;
      this[handler](rating);
    },
    async shouldShowDailyFortuneRating() {
      const buildNumber = parseInt(this.$route.query.build_number);
      if (!(buildNumber > 176 &&
          this.userAccDailyfortuneCount > 4 &&
          this.tarotBoxHistory.tarot_box_id === 320)) {
        return false;
      }

      return new Promise((resolve) => {
        const timeout = setTimeout(() => {
          subscription?.unsubscribe();
          resolve(false);
        }, 1500);

        const subscription = this.appListener.subscribeSingleAction('is_popup_shown', (data) => {
          clearTimeout(timeout);
          resolve(!data.isPopupShown);
          subscription?.unsubscribe();
        });

        this.appCommander.callAppAction('is_popup_shown', {
          popup_id: 'ratings'
        });
      });
    },

    async shouldShowExtraQuestionRating() {
      const buildNumber = parseInt(this.$route.query.build_number);
      if (!(buildNumber > 176)) {
        return false;
      }

      return new Promise((resolve) => {
        const timeout = setTimeout(() => {
          subscription?.unsubscribe();
          resolve(false);
        }, 1500);

        const subscription = this.appListener.subscribeSingleAction('is_popup_shown', (data) => {
          clearTimeout(timeout);
          resolve(!data.isPopupShown);
          subscription?.unsubscribe();
        });

        this.appCommander.callAppAction('is_popup_shown', {
          popup_id: 'ratings'
        });
      });
    },
    async getCurrentRatingConfig() {
      const shouldShowDaily = await this.shouldShowDailyFortuneRating();
      return shouldShowDaily
          ? this.ratingPopupConfig.dailyFortune
          : this.ratingPopupConfig.review;
    },
    async exitResultPage() {
      const shouldShow = await this.shouldShowDailyFortuneRating();
      if(shouldShow) {
        const config = await this.getCurrentRatingConfig();
        this.currentRatingConfig = config;
        this.isRatingsPopupOpen = true;
      } else {
        if(this.tarotBoxHistory.feedback_reward_status === 'unavailable') {
          changeBottomSafeAreaColor('#FF1E2352');
          changeTopSafeAreaColor('#FFFFFFFF');
          showBottomBar();
          this.goMytarotPage();
        } else if(this.isReviewPopupOpen === false && this.$isWeb() !== true) {
          this.showReviewPopup()
        } else {
          changeBottomSafeAreaColor('#FF1E2352');
          changeTopSafeAreaColor('#FFFFFFFF');
          showBottomBar();
          this.goMytarotPage();
        }
      }
    },
    handleDailyFortuneRatingSubmit(rating) {
      console.log('rating', rating)
      this.isRatingsPopupOpen = false;
      this.$router.go(-1);
      changeBottomSafeAreaColor('#FF1E2352');
      changeTopSafeAreaColor('#FFFFFFFF');
      showBottomBar();
    },
    async openRatingsPopupFromExtarAnswer() {
      const shouldShow = await this.shouldShowExtraQuestionRating();
      if(shouldShow) {
        this.isRatingsPopupOpen = true;
      }
    },
    handleExtraQuestionRatingSubmit() {
      this.isRatingsPopupOpen = false;
    },
    async exitFromCoverPage() {
      const shouldShow = await this.shouldShowDailyFortuneRating();
      if(shouldShow) {
        const config = await this.getCurrentRatingConfig();
        this.currentRatingConfig = config;
        this.isRatingsPopupOpen = true;
      } else {
        this.$router.go(-1);
        showBottomBar();
        changeBottomSafeAreaColor('#FF1E2352');
        changeTopSafeAreaColor('#FFFFFFFF');
        logEvent("result_cover_out_click", {});
      }
    }
  }
}
</script>

<style>
@font-face {
  font-family: 'KimjungchulMyungjo';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2302_01@1.0/KimjungchulMyungjo-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'KimjungchulMyungjo';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2302_01@1.0/KimjungchulMyungjo-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

:root {
  --gray1: var(--Grayscale-Gray1);
  --gray2: var(--Grayscale-Gray2);
  --gray3: var(--Grayscale-Gray3);
  --gray4: var(--Grayscale-Gray4);
  --gray5: var(--Grayscale-Gray5);
  --gray6: var(--Grayscale-Gray6);
  --gray7: var(--Grayscale-Gray7);
  --gray8: var(--Grayscale-Gray8);
  --gray9: var(--Grayscale-Gray9);
  --gray10: var(--Grayscale-Gray10);
  --gray11: var(--Grayscale-Gray11);
  --gray12: var(--Grayscale-Gray12);
  --bdGray9: var(--Grayscale-Gray9);
  --bdGray7: var(--Grayscale-Gray7);
  --boxGray12: var(--Grayscale-Gray12);
  --boxBorder10: var(--Grayscale-Gray10);
  --buttonPrimary: var(--Color-palette-Primary-Default);
  --buttonText: var(--Grayscale-Gray11);
  --tableHeader: var(--Grayscale-Gray4);
  --tableHeaderText: var(--Grayscale-Gray11);
  --tableColumn: var(--Grayscale-Gray10);
  --tableColumnText: var(--Grayscale-Gray4);
  --tableBorder: var(--Grayscale-Gray9);
  --textarea: #3F569E;
  --buttonProcessing: var(--Color-palette-Primary-Lighten2);
  --loaderImage: var(--Grayscale-Gray12);
  --tempGreen: #C2FFAD;
  --textareafield: rgba(255, 255, 255, 0.40);
}

.dark-mode{
  --gray1: var(--Grayscale-Gray12);
  --gray2: var(--Grayscale-Gray11);
  --gray3: var(--Grayscale-Gray10);
  --gray4: var(--Grayscale-Gray9);
  --gray5: var(--Grayscale-Gray8);
  --gray6: var(--Grayscale-Gray7);
  --gray7: var(--Grayscale-Gray6);
  --gray8: var(--Grayscale-Gray5);
  --gray9: var(--Grayscale-Gray4);
  --gray10: var(--Grayscale-Gray3);
  --gray11: var(--Grayscale-Gray2);
  --gray12: var(--Grayscale-Gray1);
  --bdGray9: var(--Grayscale-Gray4);
  --bdGray7: var(--Grayscale-Gray4);
  --boxGray12: var(--Grayscale-Gray3);
  --boxBorder10: var(--Grayscale-Gray3);
  --buttonPrimary: var(--Color-palette-Primary-Lighten4);
  --buttonText: var(--Color-palette-Primary-Default);
  --tableHeader: var(--Grayscale-Gray9);
  --tableHeaderText: var(--Grayscale-Gray2);
  --tableColumn: var(--Grayscale-Gray3);
  --tableColumnText: var(--Grayscale-Gray9);
  --tableBorder: var(--Grayscale-Gray4);
  --textarea: #D1DDFF;
  --buttonProcessing: var(--Color-palette-Primary-Lighten3);
  --loaderImage: var(--Color-palette-Primary-Default);
  --tempGreen: #247807;
  --textareafield: rgba(36, 36, 36, 0.60);

}

.serif {
  font-family: 'KimjungchulMyungjo', 'Noto Serif KR', 'Noto Serif CJK KR', 'Noto Serif', serif;
}

.regular {
  font-weight: 300;
}

.bold {
  font-weight: 700;
}

.shade {
  color: var(--Grayscale-Gray5);
}

.gray1{
  color: var(--gray1);
}

.gray2{
  color: var(--gray2);
}

.gray3{
  color: var(--gray3);
}

.gray4{
  color: var(--gray4);
  margin: 0 auto;
}

.gray5{
  color: var(--gray5);
}

.gray6{
  color: var(--gray6);
}

.gray7{
  color: var(--gray7);
}

.gray8{
  color: var(--gray8);
}

.gray9{
  color: var(--gray9);
}

.gray10{
  color: var(--gray10);
}

.gray11{
  color: var(--gray11);
}

.gray12{
  color: var(--gray12);
}

.font12{
  font-size: 12px;
}

.font14{
  font-size: 14px;
}

.font18{
  font-size: 18px;
}

.font20{
  font-size: 20px;
}

.tempGreen{
  color: var(--tempGreen);
}

.svg-fill-gray7 {
  fill : var(--bdGray7);
}

.svg-fill-gray9{
  fill: var(--bdGray9);
}

.comment-box{
  background: var(--boxGray12);
  border: 1px solid var(--boxBorder10);
}

.button-box{
  background: var(--buttonPrimary);
  color: var(--buttonText);
}

.textarea-text{
  color: var(--textarea);
}

.textarea-field{
  background: var(--textareafield);
  border: 1px solid var(--Grayscale-Gray9);
}

.output-v2-page {
  width: 100vw;
  height: 100dvh;
  display: flex;
  box-sizing: border-box;
  max-width: 480px;
  overscroll-behavior: none;
  -webkit-overflow-scrolling: auto;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.no-bounce {
  overscroll-behavior: none;
  -webkit-overflow-scrolling: auto;
}

.output-toast {
  position: fixed;
  left: 50%;
  transform: translate(-50%, -100%);
  width: 65vw;
  padding: 16px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: var(--Grayscale-Gray2, #242424);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.20);
  color: var(--Grayscale-Gray12, #FFF);
  text-align: center;
  opacity: 0;
  z-index: 9999;
  transition: opacity 0.5s, transform 0.5s;
}

.output-toast.show {
  opacity: 1;
  transform: translate(-50%, 0);
}

table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  text-align: center;
}

th,td {
  border: 1px solid var(--tableBorder);
  padding: 4px;
}

th {
  background: var(--tableHeader);
  color: var(--tableHeaderText);
}

td:first-child{
  background: var(--tableColumn);
  color: var(--tableColumnText);
}

ol{
  display: flex;
  flex-direction: column;
  gap: 16px;

  list-style-type: decimal;
  margin-block-start: 24px;
  margin-block-end: 20px;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 16px;
  unicode-bidi: isolate;
}

ul{
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  margin: -16px 0 0 0;
  word-break: keep-all
}

.custom-loader {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-img {
  position: absolute;
  width: 100px; /* 로고 이미지의 크기 */
  height: 100px;
}

.moving-svg {
  position: absolute;
  width: 30px; /* 움직이는 이미지의 크기 */
  height: 30px;
  animation: orbit 2s linear infinite;
}

@keyframes orbit {
  0% {
    transform: rotate(0deg) translateX(50px) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translateX(50px) rotate(-360deg);
  }
}

.bottom-sheet__content{
  padding-bottom: var(--bottom-sheet-padding) !important;
  box-sizing: content-box !important;
  height: auto !important;
}

.bottom-sheet{
  z-index: 99 !important;
}

.bottom-sheet__draggable-area{
  display: none !important;
}


</style>