<template>
  <div class="component-container" :style="{ paddingTop: padding}">
    <div class="heading-texts" v-safe-area-top>
      <img id="symbol" :src="tarotBoxHistory.output_page_meta_data.objet_icon_image_url" alt="symbol"/>
      <span class="font14 gray5">
        <strong>Chapter {{ currentChapterIndex }}</strong>
      </span>
      <span class="serif gray1 font20">{{ currentPageData.page_title }}</span>
    </div>

    <div class="tarot-spread-container" ref="spreadContainer" :style="{ opacity: imagesLoaded ? 1 : 0, transition: imagesLoaded ? 'opacity 0.5s' : 'none'}">
      <div class="tarot-spread" :style="spreadStyle">
        <div
            v-for="(attr, index) in currentPageData.page_content.spread_data.attrs"
            :key="index"
            class="tarot-card"
            :style="getCardPosition(attr)"
        >
          <div class="card-order gray2"><strong>{{ getCardOrder(index) }}</strong></div>
          <img
              @click="openCardInfo([currentPageData.page_content.card_data[index], currentPageData.page_content.spread_data.attrs[index]])"
              :src="getCardImageUrl(currentPageData.page_content.card_data[index].card_id)"
              :alt="currentPageData.page_content.card_data[index].card_name"
              :class="{ 'no-transition': cachedImages.includes(getCardImageUrl(currentPageData.page_content.card_data[index].card_id)) }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {logEvent} from "@/tarot_box/helper/native_api";

export default {
  name: "TarotSpread",
  props: {
    currentIndex: {
      type: Number,
    },
    currentPageData: {
      type: Object,
    },
    backgroundColor: {
      type: String,
    },
    tarotBoxHistory: {
      type: Object,
    },
    currentChapterIndex: {
      type: Number,
    },
    isShare: {
      type: Boolean,
    },
  },
  emits: ['open-card-info'],
  data() {
    return {
      spreadRatio: 1,
      imagesLoaded: false,
      cachedImages: [],
      containerWidth: 0,
      containerHeight: 0
    };
  },
  computed: {
    spreadStyle() {
      return {
        width: '100%',
        height: '100%',
        margin: '0 auto',
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)'
      };
    },
    padding() {
      return this.isShare ? '48px' : '100px';
    },
    spreadCenter() {
      const attrs = this.currentPageData.page_content.spread_data.attrs;
      const minX = Math.min(...attrs.map(attr => attr.axis_x - attr.card_display_size / 2));
      const maxX = Math.max(...attrs.map(attr => attr.axis_x + attr.card_display_size / 2));
      const centerX = (minX + maxX) / 2;

      return { x: centerX };
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$refs.spreadContainer) {
        this.resizeSpread();
        window.addEventListener('resize', this.resizeSpread);
      }
      logEvent('result_tarot_spread_into', {})
    });

    const images = this.$el.querySelectorAll('img');
    const totalImages = images.length;
    let loadedImages = 0;

    const onImageLoad = (img) => {
      loadedImages++;
      if (img.complete) {
        this.cachedImages.push(img.src);
      }
      if (loadedImages === totalImages) {
        setTimeout(() => {
          this.imagesLoaded = true;
          // 이미지가 모두 로드된 후 다시 한번 resize 실행
          this.resizeSpread();
        }, 50);
      }
    };

    images.forEach(img => {
      if (img.complete) {
        onImageLoad(img);
      } else {
        img.addEventListener('load', () => onImageLoad(img));
      }
    });
  },
  updated() {
    this.$nextTick(() => {
      if (this.$refs.spreadContainer) {
        this.resizeSpread();
      }
    });
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.resizeSpread);
  },
  methods: {
    getCardImageUrl(cardId) {
      return `https://firebasestorage.googleapis.com/v0/b/tarot-gpt.appspot.com/o/tarot_card_imgs%2Flite_0925%2F${cardId}.png?alt=media`;
    },
    resizeSpread() {
      if (!this.$refs.spreadContainer) return;

      const containerWidth = this.$refs.spreadContainer.clientWidth;
      const containerHeight = this.$refs.spreadContainer.clientHeight;

      // 스프레드의 실제 범위 계산
      const attrs = this.currentPageData.page_content.spread_data.attrs;
      const minX = Math.min(...attrs.map(attr => attr.axis_x - attr.card_display_size / 2));
      const maxX = Math.max(...attrs.map(attr => attr.axis_x + attr.card_display_size / 2));
      const minY = Math.min(...attrs.map(attr => attr.axis_y - attr.card_display_size * 1.4 / 2));
      const maxY = Math.max(...attrs.map(attr => attr.axis_y + attr.card_display_size * 1.4 / 2));

      const spreadWidth = maxX - minX;
      const spreadHeight = maxY - minY;

      // 컨테이너의 실제 사용 가능한 너비/높이 계산
      const usableWidth = containerWidth * 0.9;  // 90%만 사용
      const usableHeight = containerHeight * 0.9;  // 90%만 사용

      const widthRatio = usableWidth / spreadWidth;
      const heightRatio = usableHeight / spreadHeight;

      this.spreadRatio = Math.min(widthRatio, heightRatio);

      this.containerWidth = containerWidth;
      this.containerHeight = containerHeight;
    },
    getCardOrder(index) {
      const orders = ['첫번째', '두번째', '세번째', '네번째', '다섯번째', '여섯번째', '일곱번째', '여덟번째', '아홉번째', '열번째', '열한번째', '열두번째', '열세번째', '열네번째', '열다섯번째', '열여섯번째', '열일곱번째', '열여덟번째', '열아홉번째', '스무번째'];
      return orders[index];
    },
    openCardInfo(cardId) {
      const stringCardIds = JSON.stringify(cardId)
      logEvent('result_spread_card_click', { 'card_id': stringCardIds })
      this.$emit('open-card-info', cardId);
    },
    getCardPosition(attr) {
      // 컨테이너 width가 없을 경우 기본값 사용
      const containerWidth = this.$refs.spreadContainer?.clientWidth || window.innerWidth;
      const containerHeight = this.$refs.spreadContainer?.clientHeight || window.innerHeight;

      const relativeX = attr.axis_x - this.spreadCenter.x;

      // y축 중앙 정렬을 위한 계산 추가
      const attrs = this.currentPageData.page_content.spread_data.attrs;
      const minY = Math.min(...attrs.map(attr => attr.axis_y));
      const maxY = Math.max(...attrs.map(attr => attr.axis_y));
      const centerY = (maxY + minY) / 2;
      const relativeY = attr.axis_y - centerY;

      return {
        position: 'absolute',
        left: `${(relativeX - attr.card_display_size / 2) * this.spreadRatio + (containerWidth / 2)}px`,
        top: `${(relativeY - attr.card_display_size / 2) * this.spreadRatio + (containerHeight / 2)}px`,
        width: `${attr.card_display_size * this.spreadRatio}px`,
        height: `${attr.card_display_size * 1.4 * this.spreadRatio}px`,
        transform: attr.orientation === 'horizontal' ?
            'rotate(90deg) translate(0, -50%)' : 'none',
        'transform-origin': 'center center'
      };
    }
  },
  watch: {
    '$refs.spreadContainer'(newVal) {
      if (newVal) {
        this.resizeSpread();
      }
    }
  }
};
</script>

<style scoped>
.component-container {
  width: 100vw;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
}

.heading-texts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 6px;
}

#symbol {
  height: 30px;
}

.tarot-spread-container {
  margin-top: 20px;
  position: relative;
  width: 100vw;
  height: calc(50vh - 66px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.tarot-spread {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
}

.tarot-card {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 12px;
}

.tarot-card img {
  width: 100%;
  height: auto;
  -webkit-filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.2));
  filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.2));
  transition: opacity 0.5s;
}

.tarot-card img.no-transition {
  transition: none;
}

.card-order {
  margin-bottom: 2px;
  font-size: 10px;
}

.card-description {
  margin-top: 8px;
  font-size: 14px;
}
</style>