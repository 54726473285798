<template>
  <div v-if="currentPopup && !isWebEnv && showTarotDetail === false" class="notice-popup">
    <div class="notice-popup__content" v-safe-area-top>
      <div class="notice-popup__body">
        <img :src="currentPopup.img_url" :alt="currentPopup.title" @click="handlePopupClick"/>
      </div>
      <div class="notice-popup__buttons">
        <button @click="hidePopupForToday">다시 보지 않기</button>
        <button @click="closePopup(false)">닫기</button>
      </div>
    </div>
  </div>

  <teleport to="body">
    <AppExitPopup
        v-if="isAppExitPopupActive"
        @close="isAppExitPopupActive = false"/>
  </teleport>
  <div class="screen" v-safe-area-bottom :class="{ 'category-page-open': showCategoryPage }">
    <MainHeaderBar/>
    <MainCarousel
        :totalCarousel="totalCarousel"
        @boxSelected="openTarotDetail"/>
    <MainCategory/>
    <div class="sections-container">
      <div v-for="section in sortedHomeSections" :key="section">
        <MainRanking v-if="section.sectionType === 'ranking'"/>
        <RecommendList v-web v-else-if="section.sectionType === 'recommend' && section.tarotBoxIds.length > 0"
                       :section-data="section"/>
        <ThumbnailBoardList v-else-if="section.sectionType === 'tarot_box_card'"
                            :section-data="section"/>
        <TagBoxList v-else-if="section.sectionType === 'tarot_box_list'"
                    :title="section.title" :tarot-box-ids="section.tarotBoxIds"/>
        <ThumbnailSlideList v-else-if="section.sectionType ==='tarot_box_slide'"
                            :section-data="section"/>
      </div>
    </div>
    <TarotDetailFooterForWeb/>

    <div :class="{
      'closing-tarot-detail': closingCategoryPage,'tarot-detail-active': openingCategoryPage,'tarot-detail-re-active': reopeningCategoryPage}">
      <CategoryPage v-if="showCategoryPage"
                    :category="selectedCategory"
                    :pageAnimating="pageAnimating"
                    @close-category-page="closeCategoryPage"/>
    </div>
    <div :class="{
      'closing-tarot-detail': closingTarotDetail,'tarot-detail-active': openingTarotDetail,'tarot-detail-re-active': reopeningTarotDetail}">
      <TarotDetailPage
          v-if="showTarotDetail"
          @reset-back-button="resetBackButton"
          :closeTarotDetail="closeTarotDetail" />
    </div>
  </div>
  <ServiceErrorPopup v-if="isServiceErrorPopupActive"
                     @close="isServiceErrorPopupActive = false"/>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import MainHeaderBar from "@/tarot_box/pages/home/components/main_header_bar/MainHeaderBar.vue";
import TarotDetailPage from "@/tarot_box/pages/home/components/tarot_box_detail/pages/TarotDetailPage.vue";
import MainCarousel from "@/tarot_box/pages/home/components/main_carousel/MainCarousel.vue";
import MainCategory from "@/tarot_box/pages/home/components/main_category/MainCategory.vue";
import MainRanking from "@/tarot_box/pages/home/components/main_ranking/MainRanking.vue";
import RecommendList from "@/tarot_box/pages/home/components/RecommendList.vue";

import {
  getPopupList,
  getRecommendBoxIds,
  getTarotBoxItemsByFetch,
  recordTarotBoxClick
} from "@/tarot_box/helper/mytarot_api";
import {
  changeBottomSafeAreaColor,
  hideBottomBar,
  logEvent,
  showBottomBar,
} from "@/tarot_box/helper/native_api";
import ThumbnailBoardList from "@/tarot_box/pages/home/components/main_list_types/ThumbnailBoardList.vue";
import TagBoxList from "@/tarot_box/pages/home/components/main_list_types/TagBoxList.vue";
import CategoryPage from "@/tarot_box/pages/home/components/category_page/CategoryPage.vue";
import ThumbnailSlideList from "@/tarot_box/pages/home/components/main_list_types/ThumbnailSlideList.vue";
import TarotDetailFooterForWeb from "@/tarot_box/pages/home/components/tarot_box_detail/components/TarotDetailFooterForWeb.vue";
import AppExitPopup from "@/common/AppExitPopup.vue";
import ServiceErrorPopup from "@/ServiceErrorPopup.vue";
import dayjs from "dayjs";
import {pushWithParams} from "@/utils/pushWithParams";


export default {
  name: "HomePage",
  inject: ["eventBus"],
  components: {
    ServiceErrorPopup,
    ThumbnailSlideList,
    CategoryPage,
    TagBoxList,
    ThumbnailBoardList,
    RecommendList,
    TarotDetailPage,
    MainHeaderBar,
    MainCarousel,
    MainCategory,
    MainRanking,
    TarotDetailFooterForWeb,
    AppExitPopup,
  },
  data() {
    return {
      isWebEnv: this.$isWeb(),
      showTarotDetail: false,
      closingTarotDetail: false,
      openingTarotDetail: false,
      reopeningTarotDetail: false,
      showCategoryPage: false,
      selectedCategory: null,
      openingCategoryPage: false,
      reopeningCategoryPage: false,
      closingCategoryPage: false,
      totalCarousel: 1,
      isAppExitPopupActive: false,
      pageAnimating: false,
      isServiceErrorPopupActive: false,
      currentPopup: null,
      popupQueue: [],
      samplePopups:[],
    };
  },
  emits: ['boxSelected'],

  async mounted() {
    try {
      showBottomBar();
      this.initSafeAreaColorHistory();
      this.addSafeAreaColorTop('--Grayscale-Gray12');
      this.addSafeAreaColorBottom('#FF171B2B');
      this.eventBus.on('openTarotDetail', this.openTarotDetail);
      this.eventBus.on('openCategoryPage', this.openCategoryPage);
      document.body.style.overflow = ''; // 스크롤 활성화
      window.scrollTo(0, 0);

      let tarotUserId = new URLSearchParams(window.location.search).get('tarot_user_id');
      let phoneNumber = new URLSearchParams(window.location.search).get('phone_number');
      let utmContent = new URLSearchParams(window.location.search).get('utm_content');
      if (utmContent) {
        let webReferrer = decodeURIComponent(utmContent);
        this.$store.dispatch('setUserData', {web_referrer: webReferrer});
        this.$store.dispatch('setUtmReferrerData', webReferrer);
        localStorage.setItem('UtmReferrer', webReferrer);
        console.log('success set utmContent', webReferrer);
      }
      if (tarotUserId || phoneNumber) {
        this.$store.dispatch('setUserData', {user_id: tarotUserId, phone_number: phoneNumber});
      }


      let isWeb = this.$isWeb();
      if (!this.$store.getters.hasTarotBoxes) {
        if (isWeb) {
          const allowedParams = ['is_web', 'category', 'tarot_box_id'];
          const newQuery = {};

          // Filter and keep only allowed parameters
          for (const param of allowedParams) {
            if (this.$route.query[param]) {
              newQuery[param] = this.$route.query[param];
            }
          }
          newQuery.first = true;

          // Remove hash if present
          if (window.location.hash) {
            history.pushState("", document.title, window.location.pathname + window.location.search);
          }

          // Update route with cleaned parameters
          this.$router.push({
            query: newQuery,
            hash: ''
          });
        }
        await this.refreshItems();
        this.checkPopupState();
      } else {
        this.checkPopupState();
      }
      await this.getPopupListForHome()

      logEvent('home_into', {});
      await this.refreshItems();
      window.onBackButtonClick = this.onBackButtonClick;
    } catch (error) {
      console.error("Error in mounted:", error);
    }
  },
  beforeUnmount() {
    this.eventBus.off('openTarotDetail', this.openTarotDetail);
    if (window.onBackButtonClick === this.onBackButtonClick) {
      window.onBackButtonClick = null;
    }
    this.previousSafeAreaColorTop();
    this.previousSafeAreaColorBottom();
  },
  computed: {
    ...mapGetters(['user', 'tarotBoxes', 'promotionBanners', 'iconCategories', 'homeSections']),
    sortedHomeSections() {
      return [...this.homeSections]
          .filter(section => {
            return !(section.sectionType === 'recommend' && section.tarotBoxIds.length === 0);
          })
          .sort((a, b) => {
            return b.priority - a.priority;
          });
    },
    activePopups() {
      return [...this.samplePopups]
          .filter(popup => {
            const isActive = popup.status === 'active';
            const isNotHiddenToday = !this.isPopupHiddenToday(popup.id);
            return isActive && isNotHiddenToday;
          })
          .sort((a, b) => b.priority - a.priority);
    }
  },
  watch: {
    '$route'(to) {
      if (!to.query.tarot_box_id) {
        this.closeTarotDetail();
      }
      if (!to.query.category) {
        this.closeCategoryPage();
      }
    }
  },
  methods: {
    ...mapMutations(['initSafeAreaColorHistory']),
    ...mapActions(['setUserData', 'setTarotBoxesData', 'setRankingSubjectTitlesData','setPromotionBannersData', 'setIconCategoriesData', 'setHomeSectionsData', 'updateRecommendList','addSafeAreaColorTop', 'addSafeAreaColorBottom', 'previousSafeAreaColorTop', 'previousSafeAreaColorBottom']),
    async refreshItems() {
      try {
        let isWeb = this.$isWeb();
        let phoneNumber = new URLSearchParams(window.location.search).get('phone_number');
        let tarotUserId = new URLSearchParams(window.location.search).get('tarot_user_id');
        console.log('refreshItems', phoneNumber, tarotUserId, isWeb);
        let res = await getTarotBoxItemsByFetch(phoneNumber, tarotUserId, isWeb);
        if (res.result === 'success'){
          this.setTarotBoxesData(res.data.tarot_boxes);
          this.setPromotionBannersData(res.data.promotion_banners);
          this.setIconCategoriesData(res.data.icon_categories);
          this.setHomeSectionsData(res.data.home_sections);
          this.setRankingSubjectTitlesData(res.data.ranking_subject_titles);
          this.totalCarousel = res.data.promotion_banners.length;
        }
      } catch (error){
        console.error("Error in refreshItems:", error);
      }
    },
    async getPopupListForHome() {
      if(this.$isWeb === true){
        return;
      }
      let res = await getPopupList()
      if (res.result === 'success'){
        this.samplePopups = res.data;
        console.log(res.data)
        this.initializePopupQueue();
      }
    },
    openTarotDetail(boxId) {
      this.$router.push({query: {...this.$route.query, tarot_box_id: boxId}});
      this.openingTarotDetail = true;
      hideBottomBar();
      this.showTarotDetail = true;
      document.body.style.overflow = 'hidden'; // 스크롤 비활성화
      this.updateRecomListByApiCall(boxId);
    },
    async updateRecomListByApiCall(boxId) {
      let phoneNumber = new URLSearchParams(window.location.search).get('phone_number');
      let tarotUserId = new URLSearchParams(window.location.search).get('tarot_user_id');
      if(!phoneNumber || !tarotUserId){
        return;
      }

      try {
        console.info("prev : ", this.homeSections)
        await recordTarotBoxClick(this.user.phone_number, this.user.userId, boxId);
        const recommendList = (await getRecommendBoxIds(this.user.phone_number, this.user.userId)).data;
        console.info("recommendList : ", recommendList)
        this.updateRecommendList(recommendList);
        console.info("after : ", this.homeSections)
      } catch(error){
        console.error("Error in updateRecomListByApiCall:", error);
      }
    },

    closeTarotDetail() {
      this.openingTarotDetail = false;
      this.reopeningTarotDetail = false;
      this.closingTarotDetail = true;
      this.pageAnimating = true;
      this.previousSafeAreaColorBottom(); // 카테고리 페이지로 돌아가는 경우 타이밍 이슈로 CategoryPage.vue 대신 여기서 처리
      if(!this.showCategoryPage){
        showBottomBar();
      }
      setTimeout(() => {
        let query = {...this.$route.query};
        delete query.tarot_box_id;
        this.$router.push({query: query});
        this.showTarotDetail = false;
        document.body.style.overflow = ''; // 스크롤 활성화
        setTimeout(() => {
          this.closingTarotDetail = false;
          this.pageAnimating = false;
        }, 350); // 트랜지션 시간과 동일하게 설정
      }, 350); // 트랜지션 시간
    },

    openCategoryPage(category) {
      this.selectedCategory = category;
      this.$router.push({query: {...this.$route.query, category: category}});
      setTimeout(() => {
        this.pageAnimating = false;
      }, 350);

      const categoryObject = this.homeSections.find(categories => categories.id === category);
      if(categoryObject){
        logEvent('home_section_more_click', {
          "section_name": categoryObject.title,
          "section_type": categoryObject.sectionType,});
      }
      hideBottomBar();
      changeBottomSafeAreaColor('#FFFFFFFF');
      this.openingCategoryPage = true;
      this.showCategoryPage = true;
    },

    closeCategoryPage() {
      let query = {...this.$route.query};
      delete query.category;
      this.$router.push({query: query});
      this.openingCategoryPage = false;
      this.reopeningCategoryPage = false;
      this.closingCategoryPage = true;
      this.pageAnimating = true;
      showBottomBar();
      setTimeout(() => {
        this.pageAnimating = false;
        this.showCategoryPage = false;
        setTimeout(() => {
          this.closingCategoryPage = false;
        }, 350); // 트랜지션 시간과 동일하게 설정
        this.pageAnimating = false;
      }, 350); // 트랜지션 시간
    },
    resetBackButton() {
      window.onBackButtonClick = null;
      window.onBackButtonClick = this.onBackButtonClick;
    },
    onBackButtonClick() {
      if(this.currentPopup){
        return;
      }
      if(this.showTarotDetail === true){
        this.closeTarotDetail();
        return 'back';
      }
      else if(this.showCategoryPage === true) {
        this.closeCategoryPage();
        return 'back';
      }
      else if(this.isAppExitPopupActive === true){
        return;
      }
      else {
        this.isAppExitPopupActive = true;
      }
    },
    checkPopupState(){
      if(this.$route.query.category){
        this.selectedCategory = this.$route.query.category;
        document.body.style.overflow = 'hidden';
        document.body.style.overscrollBehavior = 'contain';
        this.reopeningCategoryPage = true;
        this.showCategoryPage = true;
      }

      if(this.$route.query.tarot_box_id){
        document.body.style.overflow = 'hidden'; // 스크롤 비활성화
        document.body.style.overscrollBehavior = 'contain';
        this.reopeningTarotDetail = true;
        this.showTarotDetail = true;
      }
    },
    isPopupHiddenToday(popupId) {
      const hiddenUntil = localStorage.getItem(`popup_hidden_${popupId}`);
      if (!hiddenUntil) return false;

      const isHidden = dayjs().isBefore(dayjs(hiddenUntil));
      return isHidden;
    },

    initializePopupQueue() {
      this.popupQueue = [...this.activePopups];
      this.showNextPopup();
    },

    showNextPopup() {
      this.currentPopup = this.popupQueue.shift() || null;
      if (this.currentPopup) {
        document.body.style.overflow = 'hidden';
      }

      if (this.currentPopup) {
        logEvent('home_popup', {
          popup_id: this.currentPopup.id,
          popup_title: this.currentPopup.title
        });
      }
    },

    hidePopupForToday() {
      if (!this.currentPopup) return;

      const hideUntil = dayjs().add(24, 'year').toISOString();
      const key = `popup_hidden_${this.currentPopup.id}`;
      localStorage.setItem(key, hideUntil);

      logEvent('home_popup_hide_btn_click', {
        popup_id: this.currentPopup.id,
        popup_title: this.currentPopup.title
      });

      this.closePopup(true);
    },

    closePopup(contentClick = false) {
      document.body.style.overflow = '';
      if(contentClick === false){
        logEvent('home_popup_close_btn_click', {
          popup_id: this.currentPopup.id,
          popup_title: this.currentPopup.title
        });
      }
      this.currentPopup = null;
      if (this.popupQueue.length > 0) {
        this.showNextPopup();
      }
    },
    handlePopupClick() {
      pushWithParams(this.$router, this.currentPopup.target_url, true);
      logEvent('home_popup_content_click', {
        popup_id: this.currentPopup.id,
        popup_title: this.currentPopup.title
      });
      this.closePopup(true);
    }
  },
}
</script>

<style scoped>
.screen{
  position: relative;
  width: 100dvw;
  max-width: 480px;
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin: 0 auto;
}
.screen::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}

.screen.category-page-open {
  height: 95dvh;
  overflow: hidden;
}

.sections-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: auto;
  gap: 38px;
  margin-bottom: 38px;
}

.closing-tarot-detail {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 100%;
  z-index: 100;
  width: 100dvw;
  transform: translateX(0%);
  transition: transform 0.35s ease-in;
}

.tarot-detail-active {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 100%; /* 수정된 부분 */
  z-index: 100;
  width: 100dvw;
  transition: transform 0.35s ease-out;
  transform: translateX(-100%);
}

.tarot-detail-re-active {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 100%; /* 수정된 부분 */
  z-index: 100;
  width: 100dvw;
  transform: translateX(-100%);
}

.animation-block {
  pointer-events: none; /* 클릭 및 다른 포인터 이벤트를 비활성화 */
}


.notice-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(5px);
}

.notice-popup__content {
  width: calc(100vw - 60px);
  max-width: 440px;
  border-radius: 20px;
  overflow: hidden;
  background-color: transparent;
}

.notice-popup__body {
  line-height: 0;
}

.notice-popup__body img {
  width: 100%;
  object-fit: contain;
  border-radius: 20px 20px 0 0;
}

.notice-popup__buttons {
  display: flex;
  height: 48px;
}

.notice-popup__buttons button {
  flex: 1;
  border: none;
  background-color: var(--Grayscale-Gray3);
  color: white;
  font-size: 14px;
  cursor: pointer;
}

.notice-popup__buttons button:first-child {
  border-radius: 0 0 0 20px;
  border-right: 1px solid var(--Grayscale-Gray4);
}

.notice-popup__buttons button:last-child {
  border-radius: 0 0 20px 0;
}

</style>