<template>
  <IntersectionObserverComp v-on:triggerFadeIn="fadeIn">
    <transition v-on:enter="enter">
      <div v-if="isShow" ref="thumbnailWrapper">
        <div class="main-recommend">
          <TitleOnlyText :title="sectionData.title"/>
          <div class="thumbnail-area"
               @touchstart.passive="handleTouchStart"
               @touchend="handleTouchEnd">
            <ThumbnailImageM v-for="tarotBox in sectionData.tarotBoxIds"
                             :key="tarotBox"
                             :src="tarotBoxes.find(box => box.id === tarotBox)?.thumbnailImgUrl || ''"
                             @click="handleCardClick(tarotBox)"/>
          </div>
        </div>
      </div>
    </transition>
  </IntersectionObserverComp>
</template>

<script>
import { defineComponent } from "vue";
import TitleOnlyText from "@/tarot_box/pages/home/components/common/title/TitleOnlyText.vue";
import ThumbnailImageM from "@/tarot_box/pages/home/components/common/thumbnail_image/ThumbnailImageM.vue";
import { mapGetters } from "vuex";
import { logEvent } from "@/tarot_box/helper/native_api";
import IntersectionObserverComp from "@/common/IntersectionObserverComp.vue";

export default defineComponent({
  name: "ThumbnailSlideList",
  components: { IntersectionObserverComp, ThumbnailImageM, TitleOnlyText },
  inject: ["eventBus"],
  mounted() {
    this.checkIfSwipeNeeded();
  },
  computed: {
    ...mapGetters(['tarotBoxes']),
  },
  props: {
    sectionData: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      hasSwiped: false,
      needSwipe: false,
      isShow: false,
    };
  },
  methods: {
    handleCardClick(boxId) {
      this.eventBus.emit('openTarotDetail', boxId);
      const box = this.tarotBoxes.find(box => box.id === boxId);
      const boxTitle = box ? box.title : '';
      logEvent('home_recommend_thumb_click', { "tarot_box_id": boxId, "tarot_box_name": boxTitle });
      logEvent('home_contents_click', {
        "tarot_box_id": boxId,
        "tarot_box_name": boxTitle,
        "click_origin": "home_section_slide",
        "click_origin_name": this.sectionData.title
      });
    },
    handleTouchStart(e) {
      this.startX = e.touches[0].clientX;
    },
    handleTouchEnd(e) {
      this.endX = e.changedTouches[0].clientX;
      const diff = this.startX - this.endX;

      if (Math.abs(diff) > 50 && !this.hasSwiped && this.needSwipe) {
        logEvent('home_recommend_thumb_swipe', {});
        this.hasSwiped = true;
      }
    },
    checkIfSwipeNeeded() {
      this.$nextTick(() => {
        if(!this.isShow) {
          return;
        }

        const container = this.$el.querySelector('.thumbnail-area');
        if (!container) {
          console.warn('Thumbnail area not found');
          return;
        }

        const thumbnails = container.querySelectorAll('ThumbnailImageM');
        let totalWidth = 0;

        thumbnails.forEach(thumb => {
          const thumbWidth = thumb.offsetWidth;
          const marginRight = parseInt(window.getComputedStyle(thumb).marginRight, 10) || 0;
          totalWidth += thumbWidth + marginRight;
        });

        this.needSwipe = totalWidth > window.innerWidth;
      });
    },
    enter(el) {
      el.style.opacity = 0;
    },
    fadeIn() {
      this.isShow = true;
      this.$nextTick(() => {
        if (this.$refs.thumbnailWrapper) {
          this.$refs.thumbnailWrapper.style.transition = "opacity 1s";
          this.$refs.thumbnailWrapper.style.opacity = 1;
        }
        // fadeIn 완료 후 swipe 검사
        this.checkIfSwipeNeeded();
      });
    },
  },
});
</script>

<style scoped>
.main-recommend {
  width: 100vw;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  overflow: visible;
  padding-left: 16px;
}

.thumbnail-area {
  margin-left: -16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  overflow-x: scroll;
}

.thumbnail-area :first-child {
  margin-left: 16px;
}

.fade-in {
  opacity: 0; /* 초기 상태 */
  transition: opacity 1s; /* 트랜지션 */
}
</style>
